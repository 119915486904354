import merge from 'lodash/merge'
import { EFieldType, IComponentSchemaBase } from '../..'
import {
  IPersonalDataTagDefinitionComponent,
  personalDataDefinitionSchema
} from '../../data/personalDataTagDefinition'
import {
  tagDefinitionSchema,
  IDataTagDefinitionComponent
} from '../../data/tagDefinition'
import { ICustomCodes, IServiceAgreements } from '../advancedSettings'

export interface IGlobalRegistrationComponent
  extends ICustomCodes,
    IServiceAgreements {
  __component: 'global.registration'
  id?: string
  tags: IDataTagDefinitionComponent[]
  personalData: IPersonalDataTagDefinitionComponent[]
}

export type IGlobalRegistrationComponentSchema = IComponentSchemaBase<
  IGlobalRegistrationComponent
>

export const personalDataOptions = [
  personalDataDefinitionSchema.getDefaultValues({
    name: 'address',
    label: 'Address',
    required: false
  }),
  personalDataDefinitionSchema.getDefaultValues({
    name: 'phoneNumber',
    label: 'Phone number',
    required: false
  }),
  personalDataDefinitionSchema.getDefaultValues({
    name: 'dateOfBirth',
    label: 'Date of birth',
    required: false
  })
]

export const registrationSchema: IGlobalRegistrationComponentSchema = {
  key: 'global.registration',
  description: 'Registration component',
  icon: 'globe',
  name: 'Registration',
  getDefaultValues: values =>
    merge(
      {
        __component: 'global.registration',
        tags: [],
        personalData: personalDataOptions
      },
      values
    ),
  fields: [
    {
      key: 'personalData',
      type: EFieldType.question,
      label: 'Personal Details',
      repeatableSchema: personalDataDefinitionSchema
    },
    {
      key: 'tags',
      type: EFieldType.array,
      label: 'Additional questions',
      repeatableSchema: tagDefinitionSchema,
      repeatableLabel: (index: number, field: IDataTagDefinitionComponent) => {
        if (field.label && field.name) return `${field.label} (${field.name})`
        return field.label || field.name || `Question ${index}`
      }
    }
  ]
}
