import merge from 'lodash/merge'

import { EFieldType, IComponentSchemaBase } from '../..'
import { IColor } from '../../..'

export interface IDesignColorsComponent {
  primary: IColor
  secondary: IColor
  tertiary: IColor
  dark: IColor
  light: IColor
}

export type IDesignColorsComponentSchema = IComponentSchemaBase<
  IDesignColorsComponent
>

export const colorsSchema: IDesignColorsComponentSchema = {
  key: 'design.colors',
  description: 'Color palette',
  name: 'Colors',
  getDefaultValues: values =>
    merge(
      {
        primary: '#0074fe',
        secondary: '#4ECDC4',
        tertiary: '#FF6B6B',
        light: '#FFF',
        dark: '#292F36'
      },
      values
    ),
  fields: [
    {
      key: 'colors.primary',
      type: EFieldType.text,
      label: 'Primary'
    },
    {
      key: 'colors.secondary',
      type: EFieldType.text,
      label: 'Secondary'
    },
    {
      key: 'colors.tertiary',
      type: EFieldType.text,
      label: 'Tertiary'
    },
    {
      key: 'colors.dark',
      type: EFieldType.text,
      label: 'Dark'
    },
    {
      key: 'colors.light',
      type: EFieldType.text,
      label: 'Light'
    }
  ]
}
