import client from '@sitebuilder/api/client'
import get from 'lodash/get'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fetchOrganisations } from '@sitebuilder/api/organisations'
import { IUser } from '@sitebuilder/api'
import { EStatus } from '@sitebuilder/api/status'
import { Reducer } from 'redux'
import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import { IState, IStore } from '..'
import { setFlash, ETheme } from '../flash'

// Config
export interface ISessionResponse {
  token: string
  user: IUser
}
export interface ISessionState extends ISessionResponse {
  isAdmin: boolean
}

enum EActionTypes {
  CLEAR_SESSION = 'admin/session/CLEAR_SESSION',
  STORE_SESSION = 'admin/session/STORE_SESSION'
}

// Actions
interface IClearSessionAction {
  type: EActionTypes.CLEAR_SESSION
}

interface IStoreSessionAction {
  type: EActionTypes.STORE_SESSION
  payload: ISessionResponse
}

type ISessionAction = IClearSessionAction | IStoreSessionAction

export const clearSession = (): ISessionAction => ({
  type: EActionTypes.CLEAR_SESSION
})

export const storeSession = (state: ISessionResponse): ISessionAction => ({
  type: EActionTypes.STORE_SESSION,
  payload: state
})

// API Helpers
export const setupAPIHandlers = (store: IStore) => {
  client.interceptors.request.use((config: AxiosRequestConfig) => {
    const { session } = store.getState()

    if (session.token) {
      config.headers.Authorization = `Bearer ${session.token}`
    } else {
      delete config.headers.Authorization
    }

    return config
  })

  client.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      if (get(error, 'response.status') === 401) {
        store.dispatch(setFlash('Your session has expired', ETheme.FAILURE))
        store.dispatch(clearSession())
      }

      return Promise.reject(error)
    }
  )
}

// Selectors/Hooks
export const useSession = () => useSelector((state: IState) => state.session)
export const useUser = () => useSelector((state: IState) => state.session.user)

export const useSessionOrganisations = () => {
  const session = useSession()
  const [organisations, setOrganisations] = useState([])
  const [status, setStatus] = useState(EStatus.FETCHING)

  useEffect(() => {
    if (session.isAdmin) {
      fetchOrganisations()
        .then(organisations => setOrganisations(organisations))
        .then(() => setStatus(EStatus.FETCHED))
    } else {
      setOrganisations(session.user?.organisations)
      setStatus(EStatus.FETCHED)
    }
  }, [])

  return {
    organisations,
    status
  }
}

// Reducer
const initialState = {
  isAdmin: null,
  token: null,
  user: null
}

const reducer: Reducer<ISessionState> = (
  state = initialState,
  action: ISessionAction
) => {
  switch (action.type) {
    case EActionTypes.CLEAR_SESSION:
      return initialState
    case EActionTypes.STORE_SESSION:
      return {
        ...action.payload,
        isAdmin: get(action, 'payload.user.role.name') === 'Admin'
      }
    default:
      return state
  }
}

export default reducer
