import merge from 'lodash/merge'
import startCase from 'lodash/startCase'
import { ISite, IRichTextElement } from '../../..'
import {
  EFieldType,
  ELinkType,
  ESocialLinkType,
  IComponentSchemaBase
} from '../..'
import { buttonSchema, IUtilityButtonComponent } from '../../utility/button'
import { layoutSchema, IUtilityLayoutComponent } from '../../utility/layout'
import { linkSchema, IUtilityLinkComponent } from '../../utility/link'
import {
  socialLinkSchema,
  IUtilitySocialLinkComponent
} from '../../utility/socialLink'

export interface IGlobalFooterComponent {
  __component: 'global.footer'
  id?: string
  buttons: IUtilityButtonComponent[]
  copy: IRichTextElement
  layout: IUtilityLayoutComponent
  links: IUtilityLinkComponent[]
  showLinks: boolean
  showShareButtons: boolean
  socialLinks: IUtilitySocialLinkComponent[]
}

export type IGlobalFooterComponentSchema = IComponentSchemaBase<
  IGlobalFooterComponent
>

export const footerSchema: IGlobalFooterComponentSchema = {
  key: 'global.footer',
  description: 'Footer component',
  icon: 'globe',
  name: 'Footer',
  getDefaultValues: values =>
    merge(
      {
        __component: 'global.footer',
        copy: [{ type: 'paragraph', children: [{ text: '' }] }],
        showLinks: true,
        buttons: [
          buttonSchema.getDefaultValues({
            label: 'Donate',
            link: {
              type: ELinkType.donate,
              url: '/donate'
            },
            design: {
              background: 'secondary',
              foreground: 'light'
            }
          }),
          buttonSchema.getDefaultValues({
            label: 'Sign up',
            link: {
              type: ELinkType.signup,
              url: '/signup'
            },
            design: {
              background: 'primary',
              foreground: 'light'
            }
          })
        ],
        links: [
          linkSchema.getDefaultValues({
            label: 'Login',
            config: {
              type: ELinkType.login,
              url: '/login'
            }
          })
        ],
        showShareButtons: true,
        socialLinks: [
          socialLinkSchema.getDefaultValues({
            type: ESocialLinkType.facebook,
            url: 'https://www.facebook.com'
          }),
          socialLinkSchema.getDefaultValues({
            type: ESocialLinkType.twitter,
            url: 'https://www.twitter.com'
          }),
          socialLinkSchema.getDefaultValues({
            type: ESocialLinkType.youtube,
            url: 'https://www.youtube.com'
          })
        ],
        layout: layoutSchema.getDefaultValues({
          design: {
            maxWidth: 64,
            spacing: [1.5, 1.5, 1.5, 1.5],
            background: 'dark',
            foreground: 'light'
          }
        })
      },
      values
    ),
  fields: [
    {
      key: 'copy',
      type: EFieldType.richtext,
      label: 'Copy',
      inline: true
    },
    {
      key: 'showLinks',
      type: EFieldType.toggle,
      label: 'Show Links'
    },
    {
      key: 'links',
      type: EFieldType.array,
      label: 'Links',
      repeatableSchema: linkSchema,
      repeatableLabel: 'label',
      config: {
        disableReorder: true
      }
    },
    {
      key: 'buttons',
      type: EFieldType.array,
      label: 'Buttons',
      repeatableSchema: buttonSchema,
      repeatableLabel: 'label',
      config: {
        disableAdd: true,
        disableRemove: true,
        disableReorder: true,
        isItemDisabled: (site: ISite, item: IUtilityButtonComponent) => {
          const isFundraisingEnabled = site.campaign.fundraising.isEnabled
          const isSignUp = item.link.type === ELinkType.signup

          return !isFundraisingEnabled && isSignUp
        }
      }
    },
    {
      key: 'showShareButtons',
      type: EFieldType.toggle,
      label: 'Show Social Links'
    },
    {
      key: 'socialLinks',
      type: EFieldType.array,
      label: 'Social Links',
      repeatableSchema: socialLinkSchema,
      repeatableLabel: (_i: number, field: IUtilitySocialLinkComponent) =>
        startCase(field.type)
    },
    {
      key: 'layout.design',
      type: EFieldType.design,
      label: 'Design',
      fieldGroup: 'Design',
      visibleFields: ['theme']
    },
    {
      key: 'layout.design.maxWidth',
      type: EFieldType.range,
      label: 'Width',
      fieldGroup: 'Design',
      config: {
        min: 50,
        max: 100,
        options: [
          { label: 'Small', value: '50' },
          { label: 'Default', value: '64' },
          { label: 'Large', value: '80' }
        ]
      }
    }
  ]
}
