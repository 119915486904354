import merge from 'lodash/merge'
import { EFieldType, IComponentSchemaBase } from '../..'

export interface IGlobalTrackingComponent {
  __component: 'global.tracking'
  id?: string
  gaId: string
}

export type IGlobalTrackingComponentSchema = IComponentSchemaBase<
  IGlobalTrackingComponent
>

export const trackingSchema: IGlobalTrackingComponentSchema = {
  key: 'global.tracking',
  description: 'Add customised tracking IDs',
  name: 'Tracking',
  getDefaultValues: values =>
    merge(
      {
        __component: 'global.tracking',
        gaId: null
      },
      values
    ),
  fields: [
    {
      key: 'gaId',
      type: EFieldType.text,
      placeholder: 'G-XXXXXXXX',
      label: 'Google Analytics Tracking ID (GA4)'
    }
  ]
}
