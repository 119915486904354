import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import sample from 'lodash/sample'
import client from '../client'
import { IAttachment, IFile, ISite } from '..'

export interface IAttachmentResponse {
  category: string
  created_at: string
  file: IFile
  id: string
  site: ISite
  title: string
  type: 'file' | 'image'
  updated_at: string
}

interface IAttachmentRequest {
  file: File
  site: string
  title: string
  type: 'file' | 'image'
}

export const deserializeAttachment = (
  data: IAttachmentResponse
): IAttachment => {
  return {
    category: data.category,
    createdAt: data.created_at,
    file: data.file,
    id: data.id,
    site: data.site,
    title: data.title,
    type: data.type || 'image',
    updatedAt: data.updated_at,
    url: data.file?.url
  }
}

export const prepareAttachment = (data: IAttachmentRequest) => {
  const formData = new FormData()

  formData.append('files.file', data.file, data.file.name)
  formData.append(
    'data',
    JSON.stringify({
      site: data.site,
      title: data.title,
      type: data.type || 'image'
    })
  )

  return formData
}

export const getImage = (
  attachments: IAttachment[],
  category: string,
  index = 0
): Partial<IFile> =>
  get(
    orderBy(
      attachments.filter(attachment => attachment.category === category),
      ['title', 'id'],
      ['asc', 'asc']
    )[index],
    'file',
    {}
  )

export const getRandomImage = (
  attachments: IAttachment[],
  category?: string
): Partial<IFile> =>
  get(
    sample(
      attachments.filter(attachment =>
        category ? attachment.category === category : true
      )
    ),
    'file',
    {}
  )

export const fetchAttachments = (site?: string): Promise<IAttachment[]> =>
  client
    .get(site ? `/attachments/${site}` : '/attachments')
    .then(res => res.data)
    .then(data => data.map(deserializeAttachment))

export const fetchPlaceholderAttachments = (
  category?: string
): Promise<IAttachment[]> =>
  client
    .get('/attachments/placeholders', { params: { category } })
    .then(res => res.data)
    .then(data => data.map(deserializeAttachment))

export const createAttachment = (
  data: IAttachmentRequest
): Promise<IAttachment> =>
  client
    .post(`/attachments`, prepareAttachment(data), {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(response => deserializeAttachment(response.data))

export const deleteAttachment = (data: IAttachment) =>
  client.delete(`/attachments/${data.id}`)
