import { Reducer } from 'redux'
import { useSelector } from 'react-redux'
import { IState, StoreDispatch } from '..'
import { IUser } from '@sitebuilder/api'
import {
  fetchUsers as fetchUsersData,
  IUsersParams
} from '@sitebuilder/api/users'
import { EStatus } from '@sitebuilder/api/status'
import { formatError } from '@sitebuilder/api/errors'
import { convertToArray, convertToObject } from '../../lib/utils'
import { setFlash, ETheme } from '../flash'

// Types
export interface IUsersState {
  data: {
    [key: string]: IUser
  }
  status: EStatus
}

enum EActionTypes {
  FETCH = 'admin/users/FETCH',
  STORE = 'admin/users/STORE'
}

// Actions
interface IFetchUsersAction {
  type: EActionTypes.FETCH
}

interface IStoreUsersAction {
  type: EActionTypes.STORE
  payload: IUser[]
}

type IUsersAction = IFetchUsersAction | IStoreUsersAction

export const fetchUsers = (params?: IUsersParams) => async (
  dispatch: StoreDispatch
) => {
  return Promise.resolve()
    .then(() => dispatch({ type: EActionTypes.FETCH }))
    .then(() => fetchUsersData(params))
    .then(data => dispatch({ type: EActionTypes.STORE, payload: data }))
    .catch(error => dispatch(setFlash(formatError(error), ETheme.FAILURE)))
}

// Selector
export const useUsers = () =>
  useSelector((state: IState) => convertToArray<IUser>(state.users.data))

export const useUsersStatus = () =>
  useSelector((state: IState) => state.users.status)

// Reducer
const initialState = {
  data: {},
  status: EStatus.PENDING
}

const reducer: Reducer<IUsersState> = (
  state = initialState,
  action: IUsersAction
) => {
  switch (action.type) {
    case EActionTypes.FETCH:
      return {
        data: {},
        status: EStatus.FETCHING
      }

    case EActionTypes.STORE:
      return {
        data: {
          ...state.data,
          ...convertToObject(action.payload, 'username')
        },
        status: EStatus.FETCHED
      }

    default:
      return state
  }
}

export default reducer
