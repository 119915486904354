interface CSSObject {
  [key: string]: any
}

const withStyles = (styles: CSSObject): CSSObject => styles

export const fonts = {
  regular: '"Roboto", "system-ui", "helvetica", sans-serif',
  mono: '"Roboto Mono", monospace'
}

export const typography = {
  h1: {
    fontFamily: fonts.regular,
    fontWeight: 300,
    fontSize: '20px'
  },
  h2: {
    fontFamily: fonts.regular,
    fontWeight: 500,
    fontSize: '20px'
  },
  h3: {
    fontFamily: fonts.regular,
    fontWeight: 500,
    fontSize: '16px'
  },
  h4: {
    fontFamily: fonts.regular,
    fontWeight: 400,
    fontSize: '14px'
  },
  h5: {
    fontFamily: fonts.regular,
    fontWeight: 400,
    fontSize: '12px',
    letterSpacing: '0.05em'
  },
  h6: {
    fontFamily: fonts.regular,
    fontWeight: 400,
    fontSize: '10px'
  },
  body: {
    fontFamily: fonts.regular,
    fontWeight: 400,
    fontSize: '16px'
  },
  button: {
    fontFamily: fonts.regular,
    fontWeight: 500,
    fontSize: '15px'
  },
  input: {
    fontFamily: fonts.regular,
    fontWeight: 400,
    fontSize: '14px'
  },
  label: {
    fontFamily: fonts.regular,
    fontWeight: 400,
    fontSize: '14px'
  },
  code: {
    fontFamily: fonts.mono,
    fontSize: '16px',
    lineHeight: '20px'
  }
}

export const colors = {
  primary: '#7A04DD',
  mediumPrimary: '#e8acec',
  lightPrimary: '#E3C2FF',
  green: '#71C043',
  blue: '#09B3EC',
  yellow: '#FFBF00',
  red: '#FF6347',
  darkGray: '#262626',
  mediumGray: '#737373',
  gray: '#D0D0D0',
  lightGray: '#E2E2E2',
  offWhite: '#F4F4F4',
  white: '#FFF',
  black: '#000',
  primaryBlue: '#1667D9',
  dangerDarkRed: '#73000A',
  warningOrange: '#F25100',
  warningOrangeLighter: '#FFE6D9'
}

export const transitions = {
  easeOut: 'all 300ms ease-out'
}

export const shadows = {
  light: '0 0 0.75rem rgba(0, 0, 0, 0.025)',
  dark: '0 0 2rem rgba(0,0,0,0.1)'
}

export const breakpoints = {
  xs: 375,
  sm: 568,
  md: 768,
  lg: 1024,
  xl: 1200
}

export const mq = (breakpoint: string, type = 'min-width') => {
  const value = breakpoints[breakpoint] || breakpoint
  return `@media (${type}: ${value}px)`
}

export const reset = {
  boxSizing: 'border-box',
  appearance: 'none',
  outline: 'none',
  border: 'none',
  boxShadow: 'none'
}

export const spacing = (value: number | number[], unit = 'em') =>
  Array.isArray(value)
    ? value.map(val => `${val}${unit}`).join(' ')
    : `${value}${unit}`

export default withStyles
