/* istanbul ignore file */
import i18n from 'i18next'
import backend from '@justgiving/i18next-dynamic-import-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import i18nConfig from './i18nConfig'

i18n
  .use(
    backend(
      lang =>
        import(
          /* webpackChunkName: "lang/[request]" */ `../lang/${lang}.json5`
        ),
      { loadDefault: true }
    )
  )
  .use(LanguageDetector)
  .init({
    ...i18nConfig,
    preload: ['en'],
    detection: {
      order: ['cookie', 'navigator'],
      lookupCookie: 'userLocale'
    }
  })

export default i18n
