import client from '../client'
import { IUser, IRole, IOrganisation } from '..'
import { snakeCaseParams } from '../params'

export interface IUserResponse {
  created_at?: string
  email?: string
  firstName: string
  id: string
  lastName: string
  image: string
  username: string
  organisations: IOrganisation[]
  provider: string
  role: IRole
  updated_at?: string
  iamTokenExpiresAt: string | null
}

export interface IUsersParams {
  createdAtGte?: string
  createdAtLte?: string
  usernameContains?: string
  updatedAtGte?: string
  updatedAtLte?: string
  _sort?: string
}

export const deserializeUser = (data: IUserResponse): IUser => ({
  id: data.id,
  firstName: data.firstName,
  image: data.image,
  lastName: data.lastName,
  organisations: data.organisations,
  provider: data.provider,
  role: data.role,
  username: data.username,
  createdAt: data.created_at,
  updatedAt: data.updated_at,
  iamTokenExpiresAt: data.iamTokenExpiresAt
})

export const fetchUsers = (params: IUsersParams = {}): Promise<Array<IUser>> =>
  client
    .get('/users', { params: snakeCaseParams(params) })
    .then(res => res.data.map(deserializeUser))
