/* istanbul ignore file */
import React, { lazy, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import createStore from './store'
import { Provider } from 'react-redux'
import { BrowserRouter, Redirect, Switch } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import { init as SentryInit } from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { IntlProvider } from 'react-intl'
import {
  getLocaleCookie,
  setLocaleCookie
} from '@sitebuilder/shared/lib/cookie'

import i18n from './initI18n'

import APIHandler from './components/utils/APIHandler'
import BaseStyles from './components/ui/BaseStyles'
import Flash from './components/ui/Flash'
import Loading from './components/ui/Loading'
import Route from './components/utils/Route'

if (window) {
  setLocaleCookie(window.navigator.language)
}

if (process.env.REACT_APP_SENTRY_DSN) {
  // All unhandled errors are to be sent to Sentry.
  // We should set up an ErrorBoundary to better control this
  SentryInit({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tracesSampleRate: 0.1,
    integrations: [new Integrations.BrowserTracing()]
  })
}

const container = document.getElementById('root')
const root = createRoot(container)

const locale = getLocaleCookie() || 'en-GB'

const Account = lazy(() => import('./components/routes/Account'))
const Login = lazy(() => import('./components/routes/Login'))
const Logout = lazy(() => import('./components/routes/Logout'))
const Organisations = lazy(() => import('./components/routes/Organisations'))
const PrivateRoute = lazy(() => import('./components/utils/PrivateRoute'))
const Site = lazy(() => import('./components/routes/Site'))
const SiteAssets = lazy(() => import('./components/routes/SiteAssets'))
const SiteContainer = lazy(() => import('./components/routes/SiteContainer'))
const SiteDesign = lazy(() => import('./components/routes/SiteDesign'))
const SiteDomain = lazy(() => import('./components/routes/SiteDomain'))
const SiteFitness = lazy(() => import('./components/routes/SiteFitness'))
const SiteFundraising = lazy(() =>
  import('./components/routes/SiteFundraising')
)
const SiteIndex = lazy(() => import('./components/routes/SiteIndex'))
const SiteMeta = lazy(() => import('./components/routes/SiteMeta'))
const SitePage = lazy(() => import('./components/routes/SitePage'))
const SitePagesListing = lazy(() =>
  import('./components/routes/SitePagesListing')
)
const SiteTracking = lazy(() => import('./components/routes/SiteTracking'))
const Sites = lazy(() => import('./components/routes/Sites'))
const Users = lazy(() => import('./components/routes/Users'))

root.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={createStore()}>
      <IntlProvider locale={locale}>
        <APIHandler>
          <BaseStyles>
            <Flash />
            <BrowserRouter>
              <Suspense fallback={<Loading />}>
                <Switch>
                  <Route exact path='/login' component={Login} />
                  <Route exact path='/logout' component={Logout} />
                  <PrivateRoute>
                    <Route path='/' component={Account}>
                      <Route
                        exact
                        path='/'
                        component={() => <Redirect to='/sites' />}
                      />
                      <Route
                        exact
                        path='/organisations'
                        component={Organisations}
                      />
                      <Route exact path='/users' component={Users} />
                      <Route exact path='/sites' component={Sites} />

                      <Route path='/sites/:site' component={Site}>
                        <Route
                          exact
                          path='/sites/:site/pages/:page'
                          component={SitePage}
                        />
                        <Route path='/sites/:site' component={SiteContainer}>
                          <Route
                            exact
                            path='/sites/:site'
                            component={SiteIndex}
                          />
                          <Route
                            exact
                            path='/sites/:site/assets'
                            component={SiteAssets}
                          />
                          <Route
                            exact
                            path='/sites/:site/design'
                            component={SiteDesign}
                          />
                          <Route
                            exact
                            path='/sites/:site/domain'
                            component={SiteDomain}
                          />
                          <Route
                            exact
                            path='/sites/:site/fitness'
                            component={SiteFitness}
                          />
                          <Route
                            exact
                            path='/sites/:site/pages'
                            component={SitePagesListing}
                          />
                          <Route
                            exact
                            path='/sites/:site/fundraising'
                            component={SiteFundraising}
                          />
                          <Route
                            exact
                            path='/sites/:site/meta'
                            component={SiteMeta}
                          />
                          <Route
                            exact
                            path='/sites/:site/tracking'
                            component={SiteTracking}
                          />
                        </Route>
                      </Route>
                    </Route>
                  </PrivateRoute>
                </Switch>
              </Suspense>
            </BrowserRouter>
          </BaseStyles>
        </APIHandler>
      </IntlProvider>
    </Provider>
  </I18nextProvider>
)
