import merge from 'lodash/merge'
import { IFile } from '../../..'
import { EFieldType, IComponentSchemaBase } from '../..'

export enum EBadgeType {
  campaignDistance = 'campaignDistance',
  campaignDonations = 'campaignDonations',
  campaignDuration = 'campaignDuration',
  campaignElevation = 'campaignElevation',
  campaignRaised = 'campaignRaised',
  distance = 'distance',
  distancePercentage = 'distancePercentage',
  donations = 'donations',
  duration = 'duration',
  elevation = 'elevation',
  hasAllBadges = 'hasAllBadges',
  hasSelfDonated = 'hasSelfDonated',
  hasSetImage = 'hasSetImage',
  hasSetStory = 'hasSetStory',
  hasSetTarget = 'hasSetTarget',
  raised = 'raised',
  raisedPercentage = 'raisedPercentage',
  firstDonation = 'firstDonation',
  hasHitTarget = 'hasHitTarget',
  startedWalking = 'startedWalking'
}

enum EBadgeDefaultImage {
  campaignDistance = 'eb70b523-0bfa-4da1-9591-3919445cd594.png',
  campaignDonations = '5a930132-f1f4-4d60-aecd-24763c84678a.png',
  campaignDuration = 'c7d2db5f-98ea-4092-814b-608ee6e30ddb.png',
  campaignElevation = 'a765a105-0ace-45cb-93ef-bb756661d10e.png',
  campaignRaised = '47690336-fd3e-48c5-8b8a-6d042ed4b727.png',
  distance = '2eaa19fa-0307-4ddc-b5d2-3410fff18fcf.png',
  distancePercentage = '2cc0b2bc-ab98-4ecd-83b0-712328dcfb06.png',
  donations = '30de7ca9-e649-4b19-ad1b-67e6bd1f9bed.png',
  duration = '0961ee03-635a-4de7-ba39-2c2d9e3cc261.png',
  elevation = 'fb8dc34a-a2cb-460d-af8d-7e9ce6b99c1e.png',
  hasAllBadges = '5d796d40-3a26-4d1a-8752-82f4ac9500bf.png',
  hasSelfDonated = '0fe5ee7e-3f39-4fb3-95ac-f80555e1dac1.png',
  hasSetImage = '8d780bbd-b5b6-4bb2-833e-ec3c11bcaa6c.png',
  hasSetStory = 'ebdc12fd-56e7-4c62-b999-88f8b87170df.png',
  hasSetTarget = '71f6541a-35eb-4cf3-91ea-86cadc3d2bd2.png',
  raised = '7dbcbb16-0b8f-4ba5-9132-25b1b63d9e73.png',
  raisedPercentage = '2326f1b2-429d-4c48-957d-47c9b1fec89e.png',
  firstDonation = '7dbcbb16-0b8f-4ba5-9132-25b1b63d9e73.png',
  hasHitTarget = '2326f1b2-429d-4c48-957d-47c9b1fec89e.png',
  startedWalking = '2eaa19fa-0307-4ddc-b5d2-3410fff18fcf.png'
}

export const getDefaultBadgeImage = (type: EBadgeType) =>
  `https://images.justgiving.com/image/${EBadgeDefaultImage[type]}`

export const getBadgeCategory = (type: string) => {
  switch (type) {
    case 'distance':
    case 'distancePercentage':
    case 'duration':
    case 'elevation':
    case 'campaignDistance':
    case 'campaignDuration':
    case 'campaignElevation':
      return 'fitness'
    default:
      return 'fundraising'
  }
}

export interface IUtilityBadgeComponent {
  __component: 'utility.badge'
  id?: string
  category?: 'fundraising' | 'fitness'
  description?: string
  image: IFile
  title: string
  type: EBadgeType
  value: number
}

export type IUtilityBadgeComponentSchema = IComponentSchemaBase<
  IUtilityBadgeComponent
>

const badgeOptions = [
  {
    label: 'First Donation',
    value: EBadgeType.firstDonation
  },
  {
    label: 'Hit Target',
    value: EBadgeType.hasHitTarget
  },
  {
    label: 'Started Walking',
    value: EBadgeType.startedWalking
  },
  {
    label: 'Amount Raised',
    value: EBadgeType.raised
  },
  {
    label: 'Percentage Raised of Target',
    value: EBadgeType.raisedPercentage
  },
  {
    label: 'Percentage Distance of Target',
    value: EBadgeType.distancePercentage
  },
  {
    label: 'Total Donations',
    value: EBadgeType.donations
  },
  {
    label: 'Total Distance',
    value: EBadgeType.distance
  },
  {
    label: 'Total Duration',
    value: EBadgeType.duration
  },
  {
    label: 'Total Elevation',
    value: EBadgeType.elevation
  },
  {
    label: 'Has Self Donated',
    value: EBadgeType.hasSelfDonated
  },
  {
    label: 'Has Set Image',
    value: EBadgeType.hasSetImage
  },
  {
    label: 'Has Set Story',
    value: EBadgeType.hasSetStory
  },
  {
    label: 'Has Set Target',
    value: EBadgeType.hasSetTarget
  },
  {
    label: 'Has All Badges',
    value: EBadgeType.hasAllBadges
  },
  {
    label: 'Campaign Total Raised',
    value: EBadgeType.campaignRaised
  },
  {
    label: 'Campaign Total Distance',
    value: EBadgeType.campaignDistance
  },
  {
    label: 'Campaign Total Donations',
    value: EBadgeType.campaignDonations
  },
  {
    label: 'Campaign Total Duration',
    value: EBadgeType.campaignDuration
  },
  {
    label: 'Campaign Total Elevation',
    value: EBadgeType.campaignElevation
  }
]

export const badgeSchema: IUtilityBadgeComponentSchema = {
  key: 'utility.badge',
  description: 'Badge',
  name: 'Badge',
  getDefaultValues: values =>
    merge(
      {
        __component: 'utility.badge',
        category: 'fundraising',
        image: null,
        type: 'raised',
        title: 'Amount Raised',
        value: null
      },
      values
    ),
  fields: [
    {
      key: 'type',
      type: EFieldType.select,
      label: 'Type',
      options: badgeOptions
    },
    {
      key: 'title',
      type: EFieldType.label,
      label: 'Title',
      config: {
        selectField: 'type',
        options: badgeOptions
      }
    },
    {
      key: 'value',
      type: EFieldType.badgeValue,
      label: 'Value'
    },
    {
      key: 'description',
      type: EFieldType.textarea,
      label: 'Description'
    },
    {
      key: 'image',
      type: EFieldType.image,
      label: 'Image'
    }
  ]
}
