import logger from 'redux-logger'
import thunk, { ThunkDispatch } from 'redux-thunk'
import {
  applyMiddleware,
  createStore,
  combineReducers,
  AnyAction,
  Reducer,
  Store
} from 'redux'
import { getLocalStorageItem, setLocalStorageItem } from '../lib/localStorage'

import editor, { IEditorState } from './editor'
import flash, { IFlashState } from './flash'
import organisations, { IOrganisationsState } from './organisations'
import pages, { IPagesState } from './pages'
import session, { ISessionState } from './session'
import sites, { ISitesState } from './sites'
import users, { IUsersState } from './users'

export interface IState {
  editor: IEditorState
  flash: IFlashState
  organisations: IOrganisationsState
  pages: IPagesState
  session: ISessionState
  sites: ISitesState
  users: IUsersState
}

export type StoreDispatch = ThunkDispatch<IState, any, AnyAction>

export interface IStore extends Store<IState> {
  dispatch: StoreDispatch
}

const reducers: Reducer<IState> = combineReducers({
  editor,
  flash,
  organisations,
  pages,
  session,
  sites,
  users
})

const middleware =
  process.env.NODE_ENV === 'production' || process.env.REACT_APP_DISABLE_LOGGER
    ? [thunk]
    : [thunk, logger]

const setupStore = () => {
  const initialState = getLocalStorageItem('admin-state')
  const store = createStore(
    reducers,
    initialState || {},
    applyMiddleware(...middleware)
  )

  store.subscribe(() => {
    const state = store.getState()
    const { session } = state

    setLocalStorageItem('admin-state', { session })
  })

  return store
}

export default setupStore
