import merge from 'lodash/merge'
import { IComponentSchemaBase } from '../..'

export interface IGlobalFitnessComponent {
  __component: 'global.fitness'
  id?: string
  fitnessEnabled: boolean
  fitnessMetric: 'Distance' | 'Custom'
  unit: string
  label: string
  startTime: string
  finishTime: string
  walk: boolean
  run: boolean
  ride: boolean
  swim: boolean
  wheelchair: boolean
  strava: boolean
  fitbit: boolean
  manual: boolean
}

export type IGlobalFitnessComponentSchema = IComponentSchemaBase<
  IGlobalFitnessComponent
>

export const fitnessSchema: IGlobalFitnessComponentSchema = {
  key: 'global.fitness',
  description: 'Fitness configuration for your site',
  name: 'Fitness',
  getDefaultValues: values =>
    merge(
      {
        __component: 'global.fitness',
        fitnessEnabled: false,
        fitnessMetric: 'Distance',
        label: 'Distance',
        unit: 'km',
        startTime: null,
        finishTime: null,
        walk: null,
        run: null,
        ride: null,
        swim: null,
        wheelchair: null,
        strava: null,
        fitbit: null,
        manual: true
      },
      values
    ),
  fields: []
}
