import mergeWith from 'lodash/mergeWith'
import isArray from 'lodash/isArray'

const merge = (...args: any[]) => {
  return mergeWith({}, ...args, (a: any, b: any) => {
    if (isArray(a)) {
      return b
    }
  })
}

export default merge
