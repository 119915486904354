import merge from 'lodash/merge'
import { IComponentSchemaBase } from '../..'
import { IDesignFontComponent } from '../font'

export interface IDesignFontsComponent {
  heading: IDesignFontComponent
  body: IDesignFontComponent
  button: IDesignFontComponent
}

export type IDesignFontsComponentSchema = IComponentSchemaBase<
  IDesignFontsComponent
>

export const fontsSchema: IDesignFontsComponentSchema = {
  key: 'design.fonts',
  description: 'Collection of fonts',
  name: 'Fonts',
  getDefaultValues: values =>
    merge(
      {
        heading: {
          fontFamily: 'DM Serif Display',
          fontWeight: 400
        },
        body: {
          fontFamily: 'DM Sans',
          fontWeight: 400
        },
        button: {
          fontFamily: 'DM Sans',
          fontWeight: 700
        }
      },
      values
    ),
  fields: []
}
