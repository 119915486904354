import React from 'react'
import { rootStyles, createMessageStyles } from './styles'
import { useDispatch } from 'react-redux'
import { useFlash, clearFlash } from '../../../store/flash'

export default function Flash () {
  const dispatch = useDispatch()
  const messages = useFlash()

  if (messages.length === 0) return null

  return (
    <div css={rootStyles.root}>
      {messages.map(message => {
        const styles = createMessageStyles(message)

        return (
          <div
            css={styles.message}
            key={message.id}
            onClick={() => dispatch(clearFlash(message.id))}>
            {message.content}
            <div css={styles.close}>&times;</div>
          </div>
        )
      })}
    </div>
  )
}
