import cloneDeep from 'lodash/cloneDeep'
import lodashSet from 'lodash/set'

export const set = <T extends object>(
  object: T,
  path: string | any[],
  value: any
): T => lodashSet(cloneDeep(object), path, value)

export default set
