type DateInput = Date | string

const lngToLocale = (lng = 'en') => {
  return lng === 'en' ? 'en-GB' : lng
}

export const formatCurrentDateISO = () => {
  const currentDate = Date.now()

  return new Date(currentDate).toISOString()
}

export const formatDateISO = (date?: DateInput) => {
  const value = new Date(date || Date.now())
  const iso = value.toISOString()

  return iso.split('T')[0]
}

const formatDate = (date: DateInput, lng: string, options: any) => {
  try {
    const value = new Date(date || Date.now())

    return new Intl.DateTimeFormat(lngToLocale(lng), options).format(value)
  } catch (error) {
    return 'Invalid Date'
  }
}

export const formatDateString = (date: DateInput, lng: string) =>
  formatDate(date, lng, {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })

export const getStartOfDay = (date?: DateInput) => {
  const value = new Date(date || Date.now())
  value.setHours(0, 0, 0, 0)

  return value
}

export const getEndOfDay = (date?: DateInput) => {
  const value = new Date(date || Date.now())
  value.setHours(23, 59, 59, 999)

  return value
}

export const addDays = (increment: number, date?: DateInput) => {
  const value = new Date(date || Date.now())
  value.setDate(value.getDate() + increment)

  return value
}

export const addMonths = (increment: number, date?: DateInput) => {
  const value = new Date(date || Date.now())
  value.setMonth(value.getMonth() + increment)

  return value
}
