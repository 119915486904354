import capitalize from 'lodash/capitalize'
import get from 'lodash/get'
import values from 'lodash/values'
import { AxiosError } from 'axios'

interface EErrorObject {
  [key: string]: string[]
}

const convertErrorObject = (object: EErrorObject): string =>
  values(object)
    .map((err: string[]) => capitalize(err.join('')))
    .join(' ')

export const formatError = (error: AxiosError) => {
  const errorCode = get(error, 'response.status')
  const errorMessage = get(
    error,
    'response.data.message',
    'There was an unexpected error'
  )
  const errorObject = get(error, 'response.data.data.errors')

  if (errorObject) {
    return convertErrorObject(errorObject)
  }

  switch (errorCode) {
    case 400:
      if (error.request.responseURL.indexOf('auth/local') > -1) {
        return 'Invalid email or password'
      }

      return typeof errorMessage === 'object'
        ? get(errorMessage, '[0].messages[0].message')
        : errorMessage
    case 401:
      return 'You must login to continue'
    case 403:
      return 'You do not have permission'
    default:
      return errorMessage
  }
}
