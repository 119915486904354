import merge from 'lodash/merge'
import uuid from 'uuid/v4'
import { EFieldType, ESocialLinkType, IComponentSchemaBase } from '../..'

export interface IUtilitySocialLinkComponent {
  __component: 'utility.socialLink'
  id?: string
  key: string
  type: ESocialLinkType
  url: string
}

export type IUtilitySocialLinkComponentSchema = IComponentSchemaBase<
  IUtilitySocialLinkComponent
>

export const socialLinkSchema: IUtilitySocialLinkComponentSchema = {
  key: 'utility.socialLink',
  description: 'Social network link',
  name: 'Social Links',
  getDefaultValues: values =>
    merge(
      {
        __component: 'utility.socialLink',
        key: uuid(),
        type: ESocialLinkType.facebook,
        url: 'https://facebook.com'
      },
      values
    ),
  fields: [
    {
      key: 'url',
      type: EFieldType.text,
      label: 'URL'
    },
    {
      key: 'type',
      type: EFieldType.select,
      label: 'Type',
      options: [
        { label: 'Facebook', value: ESocialLinkType.facebook },
        { label: 'Twitter', value: ESocialLinkType.twitter },
        { label: 'Instagram', value: ESocialLinkType.instagram },
        { label: 'LinkedIn', value: ESocialLinkType.linkedin },
        { label: 'YouTube', value: ESocialLinkType.youtube }
      ]
    }
  ]
}
