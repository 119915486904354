import findIndex from 'lodash/findIndex'
import omit from 'lodash/omit'
import merge from '../../lib/merge'
import move from 'array-move'
import set from '../../lib/set'
import { Reducer } from 'redux'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { IState, StoreDispatch } from '..'
import { IPage } from '@sitebuilder/api'
import { IContentComponent } from '@sitebuilder/api/components/content'
import {
  fetchPages as fetchPagesData,
  deletePage as destroyPage
} from '@sitebuilder/api/pages'
import { EStatus } from '@sitebuilder/api/status'
import { formatError } from '@sitebuilder/api/errors'
import { convertToArray, convertToObject } from '../../lib/utils'
import { setFlash, ETheme } from '../flash'

// Types
export interface IPagesState {
  data: {
    [key: string]: IPage
  }
  status: EStatus
}

export enum EActionTypes {
  FETCH = 'admin/pages/FETCH',
  STORE_PAGES = 'admin/pages/STORE_PAGES',
  STORE_PAGE = 'admin/pages/STORE_PAGE',
  ADD_COMPONENT = 'admin/pages/ADD_COMPONENT',
  UPDATE_COMPONENT = 'admin/pages/UPDATE_COMPONENT',
  REMOVE_COMPONENT = 'admin/pages/REMOVE_COMPONENT',
  REORDER_COMPONENT = 'admin/pages/REORDER_COMPONENT',
  SET_COMPONENT = 'admin/pages/SET_COMPONENT',
  DELETE_PAGE = 'admin/pages/DELETE_PAGE'
}

// Actions
interface IFetchPagesAction {
  type: EActionTypes.FETCH
}

interface IStorePagesAction {
  type: EActionTypes.STORE_PAGES
  payload: IPage[]
}

interface IStorePageAction {
  type: EActionTypes.STORE_PAGE
  payload: IPage
}

interface IDeletePageAction {
  type: EActionTypes.DELETE_PAGE
  payload: IPage
}

interface IAddComponentAction {
  type: EActionTypes.ADD_COMPONENT
  payload: { page: string; component: IContentComponent; index?: number }
}

interface IUpdateComponentAction {
  type: EActionTypes.UPDATE_COMPONENT
  payload: { page: string; component: string; key: string; value: any }
}

interface ISetComponentAction {
  type: EActionTypes.SET_COMPONENT
  payload: { page: string; component: string; value: IContentComponent }
}

interface IRemoveComponentAction {
  type: EActionTypes.REMOVE_COMPONENT
  payload: { page: string; component: string }
}

interface IReorderComponentAction {
  type: EActionTypes.REORDER_COMPONENT
  payload: { page: string; component: string; index: number }
}

type IPagesAction =
  | IFetchPagesAction
  | IStorePagesAction
  | IStorePageAction
  | IUpdateComponentAction
  | IAddComponentAction
  | IDeletePageAction
  | IRemoveComponentAction
  | IReorderComponentAction
  | ISetComponentAction

export const fetchPages = (site: string) => async (dispatch: StoreDispatch) => {
  return Promise.resolve()
    .then(() => dispatch({ type: EActionTypes.FETCH }))
    .then(() => fetchPagesData(site, { mode: 'draft' }))
    .then(data => dispatch({ type: EActionTypes.STORE_PAGES, payload: data }))
    .catch(error => dispatch(setFlash(formatError(error), ETheme.FAILURE)))
}

export const storePage = (page: IPage): IPagesAction => ({
  type: EActionTypes.STORE_PAGE,
  payload: page
})

export const addComponent = (
  page: string,
  component: IContentComponent,
  index?: number
) => ({
  type: EActionTypes.ADD_COMPONENT,
  payload: { page, component, index }
})

export const deletePage = (page: IPage) => async (dispatch: StoreDispatch) =>
  destroyPage(page)
    .then(data => dispatch({ type: EActionTypes.DELETE_PAGE, payload: data }))
    .then(() => dispatch(setFlash('Page deleted', ETheme.SUCCESS)))
    .catch(error => dispatch(setFlash(formatError(error), ETheme.FAILURE)))

export const updateComponent = (
  page: string,
  component: string,
  key: string,
  value: any
) => ({
  type: EActionTypes.UPDATE_COMPONENT,
  payload: { page, component, key, value }
})

export const setComponent = (
  page: string,
  component: string,
  value: IContentComponent
) => ({
  type: EActionTypes.SET_COMPONENT,
  payload: { page, component, value }
})

export const removeComponent = (page: string, component: string) => ({
  type: EActionTypes.REMOVE_COMPONENT,
  payload: { page, component }
})

export const reorderComponent = (
  page: string,
  component: string,
  index: number
) => ({
  type: EActionTypes.REORDER_COMPONENT,
  payload: { page, component, index }
})

// Selector
export const usePages = (site: string) =>
  useSelector((state: IState) =>
    convertToArray(state.pages.data).filter(page => page.site === site)
  )

export const usePage = (slug: string) =>
  useSelector((state: IState) => state.pages.data[slug])

export const usePagesStatus = () =>
  useSelector((state: IState) => state.pages.status)

export const useCurrentPage = () => {
  const params = useParams<{ page: string }>()
  return usePage(params.page)
}

// Reducer
const initialState = {
  data: {},
  status: EStatus.PENDING
}

const recursivelyOrderComponents = (
  components: IContentComponent[],
  parent: string,
  toMove: string,
  moveTo: number
) => {
  const childComponents = components.filter(
    comp => comp.config.group === parent
  )
  const itemIndex = findIndex(
    childComponents,
    comp => comp.config.key === toMove
  )
  const orderedChildComponents =
    itemIndex !== -1
      ? move(childComponents, itemIndex, moveTo)
      : childComponents

  return orderedChildComponents.reduce(
    (result: IContentComponent[], component: IContentComponent) => {
      const children = recursivelyOrderComponents(
        components,
        component.config.key,
        toMove,
        moveTo
      )

      return [...result, component, ...children]
    },
    []
  )
}

const reducer: Reducer<IPagesState> = (
  state = initialState,
  action: IPagesAction
) => {
  switch (action.type) {
    case EActionTypes.FETCH:
      return {
        data: {},
        status: EStatus.FETCHING
      }

    case EActionTypes.STORE_PAGES:
      return {
        data: {
          ...state.data,
          ...convertToObject(action.payload, 'slug')
        },
        status: EStatus.FETCHED
      }

    case EActionTypes.STORE_PAGE:
      return {
        data: {
          ...state.data,
          [action.payload.slug]: action.payload
        },
        status: EStatus.FETCHED
      }

    case EActionTypes.DELETE_PAGE:
      return {
        ...state,
        data: omit(state.data, [action.payload.slug])
      }

    case EActionTypes.ADD_COMPONENT: {
      const { page, component, index } = action.payload

      return {
        ...state,
        data: {
          ...state.data,
          [page]: {
            ...state.data[page],
            content: recursivelyOrderComponents(
              state.data[page].content.concat(component),
              null,
              component.config.key,
              index
            )
          }
        }
      }
    }

    case EActionTypes.UPDATE_COMPONENT: {
      const { page, component, key, value } = action.payload

      return {
        ...state,
        data: {
          ...state.data,
          [page]: {
            ...state.data[page],
            content: state.data[page].content.map(content => {
              if (content.config.key === component) {
                return set({ ...content }, key, value)
              } else {
                return content
              }
            })
          }
        }
      }
    }

    case EActionTypes.SET_COMPONENT: {
      const { page, component, value } = action.payload

      return {
        ...state,
        data: {
          ...state.data,
          [page]: {
            ...state.data[page],
            content: state.data[page].content.map(content => {
              if (content.config.key === component) {
                return merge(content, value)
              } else {
                return content
              }
            })
          }
        }
      }
    }

    case EActionTypes.REMOVE_COMPONENT: {
      const { page, component } = action.payload

      return {
        ...state,
        data: {
          ...state.data,
          [page]: {
            ...state.data[page],
            content: state.data[page].content.filter(
              ({ config }) => [config.key, config.group].indexOf(component) < 0
            )
          }
        }
      }
    }

    case EActionTypes.REORDER_COMPONENT: {
      const { page, component, index } = action.payload

      return {
        ...state,
        data: {
          ...state.data,
          [page]: {
            ...state.data[page],
            content: recursivelyOrderComponents(
              state.data[page].content,
              null,
              component,
              index
            )
          }
        }
      }
    }

    default:
      return state
  }
}

export default reducer
