import merge from 'lodash/merge'
import uuid from 'uuid/v4'
import { EFieldType, ELinkType, IComponentSchemaBase } from '../..'
import { IButtonDesign, ILink } from '../../..'

export interface IUtilityButtonComponent {
  __component: 'utility.button'
  id?: string
  key: string
  design?: IButtonDesign
  label: string
  link: ILink
}

export type IUtilityButtonComponentSchema = IComponentSchemaBase<
  IUtilityButtonComponent
>

export const buttonSchema: IUtilityButtonComponentSchema = {
  key: 'utility.button',
  description: 'Call to action',
  name: 'Button',
  getDefaultValues: values =>
    merge(
      {
        __component: 'utility.button',
        key: uuid(),
        design: {
          background: 'primary',
          foreground: 'light',
          spacing: [0.75, 1.25, 0.75, 1.25]
        },
        label: 'Sign Up',
        link: {
          type: ELinkType.signup,
          url: '/signup'
        }
      },
      values
    ),
  fields: [
    {
      key: 'label',
      type: EFieldType.text,
      label: 'Label'
    },
    {
      key: 'link',
      type: EFieldType.link,
      label: 'Link'
    },
    {
      key: 'design',
      label: 'Design',
      type: EFieldType.design,
      visibleFields: ['theme'],
      config: { transparent: false }
    }
  ]
}
