import merge from 'lodash/merge'
import { EFieldType, IComponentSchemaBase } from '../..'

export interface IGlobalThankYouComponent {
  __component: 'global.thankYou'
  id?: string
  enabled: boolean
  url: string
}

export type IGlobalThankYouComponentSchema = IComponentSchemaBase<
  IGlobalThankYouComponent
>

export const thankYouSchema: IGlobalThankYouComponentSchema = {
  key: 'global.thankYou',
  description: 'Connect a thank you page to your site',
  name: 'Thank You Page',
  getDefaultValues: values =>
    merge(
      {
        __component: 'global.thankYou',
        enabled: false,
        url: null
      },
      values
    ),
  fields: [
    {
      key: 'enabled',
      type: EFieldType.toggle,
      label: 'Redirect donor to a custom thank you page after donation'
    },
    {
      key: 'url',
      type: EFieldType.text,
      label: 'Thank you page URL'
    }
  ]
}
