import axios from 'axios'

export const JG_API_URL =
  process.env.JUSTGIVING_API_URL || process.env.REACT_APP_JUSTGIVING_API_URL
const JG_API_KEY =
  process.env.JUSTGIVING_API_KEY || process.env.REACT_APP_JUSTGIVING_API_KEY
const SERVICES_API_URL =
  process.env.SERVICES_API_URL || process.env.REACT_APP_SERVICES_API_URL

const JG_GQL_URL = (
  process.env.JUSTGIVING_API_URL || process.env.REACT_APP_JUSTGIVING_API_URL
).replace('api', 'graphql')

export const api = axios.create({
  baseURL: JG_API_URL,
  headers: {
    'x-api-key': JG_API_KEY
  }
})

export const authenticated = axios.create({
  baseURL: SERVICES_API_URL,
  headers: {
    'x-api-key': JG_API_KEY
  }
})

type GraphQLInput = {
  query: string
  variables?: any
}

export const authenticatedGraphqlClient = axios.create({ baseURL: JG_GQL_URL })

export const graphqlMutation = async ({ query, variables }: GraphQLInput) => {
  return authenticatedGraphqlClient.post(
    '/',
    {
      query: query,
      variables: { ...variables }
    },
    { headers: { 'content-type': 'application/json' } }
  )
}

export const graphqlClient = axios.create({ baseURL: JG_GQL_URL })

export const graphqlQuery = async ({ query, variables }: GraphQLInput) =>
  graphqlClient.post(
    '/',
    {
      query: query,
      variables: { ...variables }
    },
    { headers: { 'content-type': 'application/json' } }
  )

export const servicesApi = axios.create({
  baseURL: SERVICES_API_URL
})

export const imagesApi = axios.create({
  baseURL: JG_API_URL.replace('api', 'images')
})

export const iamApi = axios.create({
  baseURL: JG_API_URL.replace('api', 'iam-api')
})

export const oauthApi = axios.create({
  baseURL: JG_API_URL.replace('api', 'identity'),
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  auth: {
    username: process.env.JG_OAUTH_APP_ID,
    password: process.env.JG_OAUTH_APP_SECRET
  }
})

export const isStaging = JG_API_URL.indexOf('staging') > -1
