import { Reducer } from 'redux'
import { useSelector } from 'react-redux'
import { IState } from '..'

// Config
export interface IEditorState {
  activeComponent: string
  activeComponentItemIndex: number
  activeComponentItemKey: string
  componentPickerOpen: boolean
  componentPickerParent: string
  currentIndex: number
  editorOpen: boolean
  hoveredComponent: string
  mobile: boolean
  modal: string
  modalOpen: boolean
  placeholderData: boolean
  recipient: string
  isRightSideBarOpen: boolean
  isLeftSideBarOpen: boolean
}

enum EActionTypes {
  SET_ACTIVE_COMPONENT = 'admin/editor/SET_ACTIVE_COMPONENT',
  SET_HOVERED_COMPONENT = 'admin/editor/SET_HOVERED_COMPONENT',
  SET_CURRENT_INDEX = 'admin/editor/SET_CURRENT_INDEX',
  SET_PICKER_OPEN = 'admin/editor/SET_PICKER_OPEN',
  SET_EDITOR_OPEN = 'admin/editor/SET_EDITOR_OPEN',
  SET_RIGHT_SIDEBAR_OPEN = 'admin/editor/SET_RIGHT_SIDEBAR_OPEN',
  SET_LEFT_SIDEBAR_OPEN = 'admin/editor/SET_LEFT_SIDEBAR_OPEN',
  SYNC_EDITOR = 'admin/editor/SYNC_EDITOR'
}

// Actions
interface IEditorSetAction {
  type: EActionTypes.SET_ACTIVE_COMPONENT | EActionTypes.SET_HOVERED_COMPONENT
  payload: string
}

interface IEditorSetIndexAction {
  type: EActionTypes.SET_CURRENT_INDEX
  payload: number
}

interface IEditorSetEditorOpen {
  type: EActionTypes.SET_EDITOR_OPEN
  payload: { open: boolean }
}

interface IEditorSetSidebarOpen {
  type: EActionTypes.SET_RIGHT_SIDEBAR_OPEN | EActionTypes.SET_LEFT_SIDEBAR_OPEN
  payload: { open: boolean }
}

interface IEditorSetPickerOpen {
  type: EActionTypes.SET_PICKER_OPEN
  payload: { open: boolean; parent: string }
}

interface IEditorSyncAction {
  type: EActionTypes.SYNC_EDITOR
  payload: Partial<IEditorState>
}

type IEditorAction =
  | IEditorSetAction
  | IEditorSyncAction
  | IEditorSetEditorOpen
  | IEditorSetPickerOpen
  | IEditorSetIndexAction
  | IEditorSetSidebarOpen

export const setActiveComponent = (key: string) => ({
  type: EActionTypes.SET_ACTIVE_COMPONENT,
  payload: key
})

export const setHoveredComponent = (key: string) => ({
  type: EActionTypes.SET_HOVERED_COMPONENT,
  payload: key
})

export const setCurrentIndex = (index: number) => ({
  type: EActionTypes.SET_CURRENT_INDEX,
  payload: index
})

export const openEditor = () => ({
  type: EActionTypes.SET_EDITOR_OPEN,
  payload: { open: true }
})

export const closeEditor = () => ({
  type: EActionTypes.SET_EDITOR_OPEN,
  payload: { open: false }
})

export const openRightSidebar = () => ({
  type: EActionTypes.SET_RIGHT_SIDEBAR_OPEN,
  payload: { open: true }
})

export const openLeftSidebar = () => ({
  type: EActionTypes.SET_LEFT_SIDEBAR_OPEN,
  payload: { open: true }
})

export const closeRightSidebar = () => ({
  type: EActionTypes.SET_RIGHT_SIDEBAR_OPEN,
  payload: { open: false }
})

export const closeLeftSidebar = () => ({
  type: EActionTypes.SET_LEFT_SIDEBAR_OPEN,
  payload: { open: false }
})

export const openComponentPicker = (parent: string) => ({
  type: EActionTypes.SET_PICKER_OPEN,
  payload: { open: true, parent }
})

export const closeComponentPicker = () => ({
  type: EActionTypes.SET_PICKER_OPEN,
  payload: { open: false, parent: null }
})

export const openModal = (modal: string) => ({
  type: EActionTypes.SYNC_EDITOR,
  payload: { modalOpen: true, modal }
})

export const closeModal = () => ({
  type: EActionTypes.SYNC_EDITOR,
  payload: { modalOpen: false, modal: null }
})

export const togglePlaceholderData = (placeholderData: boolean) => ({
  type: EActionTypes.SYNC_EDITOR,
  payload: { placeholderData }
})

export const togglePreviewMode = (mobile: boolean) => ({
  type: EActionTypes.SYNC_EDITOR,
  payload: { mobile }
})

export const syncEditor = (state: Partial<IEditorState>) => ({
  type: EActionTypes.SYNC_EDITOR,
  payload: state
})

// Selectors
export const useEditor = () => useSelector((state: IState) => state.editor)
export const useActiveComponent = () =>
  useSelector((state: IState) => state.editor.activeComponent)
export const useHoveredComponent = () =>
  useSelector((state: IState) => state.editor.hoveredComponent)

// Reducer
const initialState = {
  activeComponent: null,
  activeComponentItemIndex: null,
  activeComponentItemKey: null,
  componentPickerOpen: false,
  componentPickerParent: null,
  currentIndex: -1,
  editorOpen: true,
  hoveredComponent: null,
  mobile: false,
  modal: null,
  modalOpen: false,
  placeholderData: true,
  recipient: null,
  isRightSideBarOpen: true,
  isLeftSideBarOpen: true
}

const reducer: Reducer<IEditorState> = (
  state = initialState,
  action: IEditorAction
) => {
  switch (action.type) {
    case EActionTypes.SET_ACTIVE_COMPONENT:
      return {
        ...state,
        activeComponent: action.payload,
        activeComponentItemIndex: null,
        activeComponentItemKey: null
      }
    case EActionTypes.SET_HOVERED_COMPONENT:
      return { ...state, hoveredComponent: action.payload }
    case EActionTypes.SET_CURRENT_INDEX:
      return { ...state, currentIndex: action.payload }
    case EActionTypes.SET_EDITOR_OPEN:
      return { ...state, editorOpen: action.payload.open }
    case EActionTypes.SET_RIGHT_SIDEBAR_OPEN:
      return { ...state, isRightSideBarOpen: action.payload.open }
    case EActionTypes.SET_LEFT_SIDEBAR_OPEN:
      return { ...state, isLeftSideBarOpen: action.payload.open }
    case EActionTypes.SET_PICKER_OPEN:
      return {
        ...state,
        componentPickerOpen: action.payload.open,
        componentPickerParent: action.payload.parent
      }
    case EActionTypes.SYNC_EDITOR:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default reducer
