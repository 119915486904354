import merge from 'lodash/merge'
import { EFieldType, IComponentSchemaBase } from '../..'
import { tagValueSchema, IDataTagValueComponent } from '../tagValue'

export enum EFormFieldType {
  select = 'select',
  radios = 'radios',
  checkboxes = 'checkboxes'
}

export interface IDataTagDefinitionComponent {
  __component: 'form.tag-definition'
  id?: string
  name: string
  label: string
  options: IDataTagValueComponent[]
  required?: boolean
  type: EFormFieldType
}

export type IDataTagDefinitionComponentSchema = IComponentSchemaBase<
  IDataTagDefinitionComponent
>

export const tagDefinitionSchema: IDataTagDefinitionComponentSchema = {
  key: 'form.tag-definition',
  description: 'Form field',
  icon: 'input',
  name: 'Field',
  getDefaultValues: values =>
    merge(
      {
        __component: 'form.tag-definition',
        name: 'source',
        label: 'How did your hear about the event?',
        options: [],
        required: false,
        type: EFormFieldType.select
      },
      values
    ),
  fields: [
    {
      key: 'name',
      type: EFieldType.text,
      label: 'Report name',
      required: true
    },
    {
      key: 'label',
      type: EFieldType.text,
      label: 'Question',
      required: true
    },
    {
      key: 'options',
      type: EFieldType.array,
      label: 'Options',
      repeatableSchema: tagValueSchema,
      repeatableLabel: (index: number, field: IDataTagValueComponent) =>
        field.label || field.value || `Option ${index}`
    },
    {
      key: 'type',
      type: EFieldType.select,
      label: 'Form field',
      options: [
        {
          label: 'Dropdown',
          value: EFormFieldType.select
        },
        {
          label: 'Radio buttons',
          value: EFormFieldType.radios
        },
        {
          label: 'Checkboxes',
          value: EFormFieldType.checkboxes
        }
      ]
    },
    {
      key: 'required',
      type: EFieldType.toggle,
      label: 'Required'
    }
  ]
}
