import client from '../client'
import { deserializeComponent } from '../components'
import { snakeCaseParams } from '../params'
import { IPage, IUser } from '..'
import { IContentComponent } from '../components/content'
import { ISiteResponse } from '../sites'
import { EStatus } from '../status'

interface IPageResponse {
  author: IUser
  content: IContentComponent[]
  created_at: string
  id: string
  isLive: boolean
  name: string
  path: string
  slug: string
  published_at: string
  site: ISiteResponse
  type: string
  updated_at: string
}

export interface IPagesParams {
  mode?: 'draft' | 'published'
  nameContains?: string
  site?: string
  createdAtGte?: string
  createdAtLte?: string
  updatedAtGte?: string
  updatedAtLte?: string
  _sort?: string
}

export interface IUpdatePageParams {
  mode?: 'draft' | 'published'
}

export const deserializePage = (data: IPageResponse): IPage => ({
  author: data.author,
  createdAt: data.created_at,
  id: data.id,
  isLive: data.isLive,
  content: data.content.map(component =>
    deserializeComponent<IContentComponent>(component)
  ),
  name: data.name,
  path: data.path,
  slug: data.slug,
  site: data.site.id,
  status: EStatus.FETCHED,
  type: data.type || 'published',
  updatedAt: data.updated_at
})

export const fetchPage = (
  site: string,
  page: string,
  params: IPagesParams = {}
) =>
  client
    .get(`/sites/${site}/pages/${page}`, { params: snakeCaseParams(params) })
    .then(res => res.data)
    .then(data => deserializePage(data))

export const fetchPages = (site: string, params: IPagesParams = {}) =>
  client
    .get(`/sites/${site}/pages`, { params: snakeCaseParams(params) })
    .then(res => res.data)
    .then(data => data.map(deserializePage))

export const createPage = async (page: Partial<IPage>): Promise<IPage> => {
  const res = await client.post(`/pages`, page)

  return deserializePage(res.data)
}

export const updatePage = async (
  id: string,
  data: Partial<IPage>,
  params: IUpdatePageParams = {}
): Promise<IPage> => {
  const res = await client.put(`/pages/${id}`, data, { params })
  return deserializePage(res.data)
}

export const setPageStatus = (page: IPage, isLive: boolean) =>
  updatePage(page.id, { isLive })

export const deletePage = async (page: IPage) =>
  await client.delete(`/pages/${page.id}`)

export const isSlugAvailable = (site: string, slug: string) =>
  client
    .get(`/sites/${site}/pages/${slug}/availability`)
    .then(response => response.data.available)

export const addFitnessTagsToFrp = (guid: string, campaignGuid: string) =>
  client.put(`/pages/${guid}/fitness-tags`, { campaignGuid })
