import merge from 'lodash/merge'
import uuid from 'uuid/v4'
import { EFieldType, ELinkType, IComponentSchemaBase } from '../..'
import { ILink } from '../../..'

export interface IUtilityLinkComponent {
  __component: 'utility.link'
  id?: string
  key: string
  label: string
  config: ILink
}

export type IUtilityLinkComponentSchema = IComponentSchemaBase<
  IUtilityLinkComponent
>

export const linkSchema: IUtilityLinkComponentSchema = {
  key: 'utility.link',
  description: 'Link',
  name: 'Link',
  getDefaultValues: values =>
    merge(
      {
        __component: 'utility.link',
        key: uuid(),
        label: 'Home',
        config: {
          type: ELinkType.page,
          url: '/'
        }
      },
      values
    ),
  fields: [
    {
      key: 'label',
      type: EFieldType.text,
      label: 'Label'
    },
    {
      key: 'config',
      type: EFieldType.link,
      label: 'Link'
    }
  ]
}
