import {
  ICharity,
  IOrganisation,
  ISite,
  ISiteColors,
  ISiteFonts,
  IUser
} from '..'

import client from '../client'
import { snakeCaseParams } from '../params'

export interface IOrganisationResponse {
  created_at: string
  charity: ICharity
  charityId: string
  iamGroup: string
  id: string
  image: string
  title: string
  slug: string
  sites: ISite[]
  colors: ISiteColors
  fonts: ISiteFonts
  privacyUrl: string
  termsUrl: string
  updated_at: string
  users: IUser[]
}

export interface IOrganisationCampaign {
  Amount: string
  CampaignGuid: string
  CharityDescription: string
  CharityId: number
  CharityLogo: string
  CharityName: string
  CountryCode: string
  EventDate: string
  EventId: string
  Link: string
  Logo: string
  Name: string
  Subtext: string
}

export interface IOrganisationsParams {
  createdAtGte?: string
  createdAtLte?: string
  titleContains?: string
  updatedAtGte?: string
  updatedAtLte?: string
  _sort?: string
}

export const deserializeOrganisation = (
  data: IOrganisationResponse
): IOrganisation => ({
  charity: data.charity,
  charityId: data.charityId,
  iamGroup: data.iamGroup,
  id: data.id,
  image: data.image,
  title: data.title,
  slug: data.slug,
  sites: data.sites,
  createdAt: data.created_at,
  updatedAt: data.updated_at,
  colors: data.colors,
  fonts: data.fonts,
  privacyUrl: data.privacyUrl,
  termsUrl: data.termsUrl,
  users: data.users
})

export const fetchOrganisation = (id: string): Promise<IOrganisation> =>
  client
    .get(`/organisations/${id}`)
    .then(res => deserializeOrganisation(res.data))

export const fetchOrganisations = (
  params: IOrganisationsParams = {}
): Promise<IOrganisation[]> =>
  client
    .get('/organisations', { params: snakeCaseParams(params) })
    .then(res => res.data.map(deserializeOrganisation))

export const fetchOrganisationCampaigns = (
  id: string,
  query: string
): Promise<IOrganisationCampaign[]> =>
  client.get(`/organisations/${id}/campaigns?q=${query}`).then(res => res.data)
