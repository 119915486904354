export const setLocalStorageItem = (key: string, val: any) => {
  try {
    const { localStorage } = window
    const serializedValue = JSON.stringify(val)
    localStorage.setItem(key, serializedValue)
  } catch (err) {
    return null
  }
}

export const getLocalStorageItem = (key: string) => {
  try {
    const { localStorage } = window
    const serializedValue = localStorage.getItem(key)
    if (serializedValue === null) {
      return null
    }
    return JSON.parse(serializedValue)
  } catch (err) {
    return null
  }
}
