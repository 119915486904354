import merge from 'lodash/merge'
import { EFieldType, IComponentSchemaBase } from '../..'
import { IFile } from '../../..'

export interface IGlobalEmailCampaignLogoComponent {
  __component: 'global.email-campaign-logo'
  id?: string
  image: IFile
}

export type IGlobalEmailCampaignLogoComponentSchema = IComponentSchemaBase<
  IGlobalEmailCampaignLogoComponent
>

export const emailCampaignLogoSchema: IGlobalEmailCampaignLogoComponentSchema = {
  key: 'global.email-campaign-logo',
  description: 'Set the email campaign logo for your site',
  name: 'Email Campaign Logo',
  getDefaultValues: values =>
    merge(
      {
        __component: 'global.email-campaign-logo',
        image: null
      },
      values
    ),
  fields: [
    {
      key: 'image',
      type: EFieldType.image,
      label: 'Email Campaign Logo'
    }
  ]
}
