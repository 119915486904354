import merge from 'lodash/merge'
import { ISite, IFile, ILink } from '../../..'
import { EFieldType, ELinkType, IComponentSchemaBase } from '../..'
import { buttonSchema, IUtilityButtonComponent } from '../../utility/button'
import { layoutSchema, IUtilityLayoutComponent } from '../../utility/layout'
import { linkSchema, IUtilityLinkComponent } from '../../utility/link'

export interface IGlobalHeaderComponent {
  __component: 'global.header'
  id?: string
  buttons: IUtilityButtonComponent[]
  layout: IUtilityLayoutComponent
  links: IUtilityLinkComponent[]
  logo: IFile
  logoLink: ILink
  mode: string
}

export type IGlobalHeaderComponentSchema = IComponentSchemaBase<
  IGlobalHeaderComponent
>

export const headerSchema: IGlobalHeaderComponentSchema = {
  key: 'global.header',
  description: 'Header component',
  icon: 'globe',
  name: 'Header',
  getDefaultValues: values =>
    merge(
      {
        __component: 'global.header',
        buttons: [
          buttonSchema.getDefaultValues({
            label: 'Donate',
            link: {
              type: ELinkType.donate,
              url: '/donate'
            },
            design: {
              background: 'secondary',
              foreground: 'light'
            }
          }),
          buttonSchema.getDefaultValues({
            label: 'Sign up',
            link: {
              type: ELinkType.signup,
              url: '/signup'
            },
            design: {
              background: 'primary',
              foreground: 'light'
            }
          })
        ],
        links: [
          linkSchema.getDefaultValues({
            label: 'Login',
            config: {
              type: ELinkType.login,
              url: '/login'
            }
          })
        ],
        layout: layoutSchema.getDefaultValues({
          design: {
            maxWidth: 64,
            spacing: [0.5, 1.5, 0.5, 1.5],
            background: 'light',
            foreground: 'dark'
          }
        }),
        logo: null,
        logoLink: {
          type: ELinkType.page,
          url: '/'
        },
        mode: 'default'
      },
      values
    ),
  fields: [
    {
      key: 'logo',
      type: EFieldType.image,
      label: 'Logo',
      inline: true
    },
    {
      key: 'logoLink',
      type: EFieldType.link,
      label: 'Logo link URL'
    },
    {
      key: 'links',
      type: EFieldType.array,
      label: 'Links',
      repeatableSchema: linkSchema,
      repeatableLabel: 'label',
      config: {
        disableReorder: true
      }
    },
    {
      key: 'buttons',
      type: EFieldType.array,
      label: 'Buttons',
      repeatableSchema: buttonSchema,
      repeatableLabel: 'label',
      config: {
        disableAdd: true,
        disableRemove: true,
        disableReorder: true,
        isItemDisabled: (site: ISite, item: IUtilityButtonComponent) => {
          const isFundraisingEnabled = site.campaign.fundraising.isEnabled
          const isSignUp = item.link.type === ELinkType.signup

          return !isFundraisingEnabled && isSignUp
        }
      }
    },
    {
      key: 'mode',
      type: EFieldType.switch,
      label: 'Display Mode',
      fieldGroup: 'Design',
      options: [
        { label: 'Default', value: 'default' },
        { label: 'Sticky', value: 'sticky' }
      ]
    },
    {
      key: 'layout.design',
      type: EFieldType.design,
      label: 'Design',
      fieldGroup: 'Design',
      visibleFields: ['theme']
    },
    {
      key: 'layout.design.maxWidth',
      type: EFieldType.range,
      label: 'Width',
      fieldGroup: 'Design',
      config: {
        min: 50,
        max: 100,
        options: [
          { label: 'Small', value: '50' },
          { label: 'Default', value: '64' },
          { label: 'Large', value: '80' }
        ]
      }
    }
  ]
}
