import createStyles, { colors } from '../../../lib/traits'
import { keyframes } from '@emotion/core'
import { ETheme } from '../../../store/flash'

const moveInOut = keyframes`
  0% {
    position: relative;
    max-height: 0;
    opacity: 0;
    transform: translateY(-50%);
  }

  15% {
    position: relative;
    max-height: 75vh;
    opacity: 1;
    transform: translateY(0%);
  }

  90% {
    position: relative;
    max-height: 75vh;
    opacity: 1;
    transform: translateY(0%);
  }

  99% {
    position: relative;
    max-height: 0;
    opacity: 0;
    transform: translateY(-150%);
  }

  100% {
    position: absolute;
    max-height: 0;
    opacity: 0;
    transform: scaleY(0) translateY(-100%);
  }
`

const mapTheme = (theme: string) => {
  switch (theme) {
    case 'success':
      return colors.green
    case 'failure':
      return colors.red
    case 'notice':
      return colors.yellow
    default:
      return colors.green
  }
}

export const rootStyles = createStyles({
  root: {
    position: 'fixed',
    zIndex: 17,
    top: '0',
    left: '50%',
    transform: 'translateX(-50%)',
    padding: '1rem',
    boxSizing: 'border-box',
    maxWidth: '27em',
    width: '100%'
  }
})

interface IParams {
  theme: ETheme
}

export const createMessageStyles = ({ theme }: IParams) =>
  createStyles({
    message: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: '0.85rem',
      cursor: 'pointer',
      marginBottom: '0.5rem',
      padding: '1rem',
      color: 'white',
      background: mapTheme(theme),
      animation: `${moveInOut} 5s ease-in-out forwards`,
      ':hover': {
        opacity: 0.75
      }
    },

    close: {
      appearance: 'none',
      cursor: 'pointer',
      fontSize: '1.75em',
      marginTop: '-0.2rem',
      marginLeft: '0.5rem'
    }
  })
