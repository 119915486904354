import { useEffect, useState } from 'react'
import { useStore } from 'react-redux'
import { setupAPIHandlers } from '../../../store/session'

const APIHandler = ({ children }) => {
  const [loaded, setLoaded] = useState(false)
  const store = useStore()

  useEffect(() => {
    setupAPIHandlers(store)
    setLoaded(true)
  }, [])

  return loaded ? children : null
}

export default APIHandler
