import merge from 'lodash/merge'
import { EFieldType, IComponentSchemaBase } from '../..'
import { IFile } from '../../..'

export interface IGlobalEmailCoverComponent {
  __component: 'global.email-cover'
  id?: string
  image: IFile
}

export type IGlobalEmailCoverComponentSchema = IComponentSchemaBase<
  IGlobalEmailCoverComponent
>

export const emailCoverSchema: IGlobalEmailCoverComponentSchema = {
  key: 'global.email-cover',
  description: 'Set the email cover phot for your site',
  name: 'Email Cover',
  getDefaultValues: values =>
    merge(
      {
        __component: 'global.email-cover',
        image: null
      },
      values
    ),
  fields: [
    {
      key: 'image',
      type: EFieldType.image,
      label: 'Email Cover'
    }
  ]
}
