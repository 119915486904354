import merge from 'lodash/merge'
import { EFieldType, IComponentSchemaBase } from '../..'

export interface IPersonalDataTagDefinitionComponent {
  __component: 'form.personal-data-definition'
  id?: string
  name: string
  label: string
  required: boolean
}

export type IPersonalDataTagDefinitionComponentSchema = IComponentSchemaBase<
  IPersonalDataTagDefinitionComponent
>

export const personalDataDefinitionSchema: IPersonalDataTagDefinitionComponentSchema = {
  key: 'form.personal-data-definition',
  description: 'Form field',
  icon: 'input',
  name: 'Field',
  getDefaultValues: values =>
    merge(
      {
        __component: 'form.personal-data-definition',
        name: '',
        label: '',
        required: false
      },
      values
    ),
  fields: []
}
