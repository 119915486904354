import merge from 'lodash/merge'
import { EFieldType, IComponentSchemaBase } from '../..'
import {
  EBadgeType,
  badgeSchema,
  IUtilityBadgeComponent
} from '../../utility/badge'

export interface IGlobalBadgesComponent {
  __component: 'global.badges'
  id?: string
  data: IUtilityBadgeComponent[]
  enabled: boolean
}

export type IGlobalBadgesComponentSchema = IComponentSchemaBase<
  IGlobalBadgesComponent
>

export const defaultBadges = [
  badgeSchema.getDefaultValues({
    type: EBadgeType.firstDonation,
    title: 'First Donation',
    description:
      "You're off and away. Share your page with your family and friends to raise as much as you can for such a great cause!"
  }),
  badgeSchema.getDefaultValues({
    type: EBadgeType.hasHitTarget,
    title: '100% Fundraising Target',
    description:
      "Well done! You've hit your fundraising target. Why not increase your target and really light up the world?"
  }),
  badgeSchema.getDefaultValues({
    type: EBadgeType.hasSetImage,
    title: 'Updated profile image',
    description:
      'Personalising your fundraising page is scientifcally proven to increase fundraising.'
  }),
  badgeSchema.getDefaultValues({
    type: EBadgeType.startedWalking,
    title: 'Started walking',
    description: 'This challenge is not going to walk itself. '
  })
]

export const badgesSchema: IGlobalBadgesComponentSchema = {
  key: 'global.badges',
  description: 'Badges component',
  icon: 'globe',
  name: 'Badges',
  getDefaultValues: values =>
    merge(
      {
        __component: 'global.badges',
        data: defaultBadges,
        enabled: true
      },
      values
    ),
  fields: [
    {
      key: 'enabled',
      type: EFieldType.toggle,
      label: 'Show Badges'
    },
    {
      key: 'data',
      type: EFieldType.array,
      label: 'Badges',
      repeatableSchema: badgeSchema,
      repeatableLabel: 'title'
    }
  ]
}
