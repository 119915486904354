export enum ECountryCode {
  au = 'au',
  ca = 'ca',
  ie = 'ie',
  nz = 'nz',
  uk = 'uk',
  us = 'us'
}

export enum ECurrencyCode {
  au = 'AUD',
  ca = 'CAD',
  ie = 'EUR',
  nz = 'NZD',
  uk = 'GBP',
  us = 'USD'
}
