import merge from 'lodash/merge'
import { EFieldType, IComponentSchemaBase } from '../..'

export interface IDataTagValueComponent {
  __component?: 'form.tag-value'
  id?: string
  value: string
  label?: string
}

export type IDataTagValueComponentSchema = IComponentSchemaBase<
  IDataTagValueComponent
>

export const tagValueSchema: IDataTagValueComponentSchema = {
  key: 'form.tag-value',
  description: 'Form Select/Radio/Checkbox Option',
  icon: 'input',
  name: 'Option',
  getDefaultValues: values =>
    merge(
      {
        __component: 'form.tag-value',
        value: 'example',
        label: 'Example value'
      },
      values
    ),
  fields: [
    {
      key: 'value',
      type: EFieldType.text,
      label: 'Report value',
      required: true,
      toggletipTranslationKey: 'admin.toggletips.reportValue'
    },
    {
      key: 'label',
      type: EFieldType.text,
      label: 'Answer',
      required: true
    }
  ]
}
