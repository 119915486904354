import merge from 'lodash/merge'
import { EFieldType, IComponentSchemaBase } from '../..'
import { IDesign } from '../../..'

export interface IUtilityLayoutComponent {
  id?: string
  design: IDesign
  customCSS?: string
}

export type IUtilityLayoutComponentSchema = IComponentSchemaBase<
  IUtilityLayoutComponent
>

export const layoutSchema: IUtilityLayoutComponentSchema = {
  key: 'utility.layout',
  description: 'Component layout',
  name: 'Layout',
  getDefaultValues: values =>
    merge(
      {
        __component: 'utility.layout',
        design: {
          alignment: 'inherit',
          background: 'transparent',
          backgroundImage: null,
          foreground: 'inherit',
          maxWidth: 50,
          spacing: [3, 1, 3, 1]
        },
        customCSS: ''
      },
      values
    ),
  fields: [
    {
      key: 'layout.design',
      type: EFieldType.design,
      label: 'Design',
      fieldGroup: 'Design'
    },
    {
      key: 'layout.customCSS',
      type: EFieldType.textarea,
      label: 'Custom CSS',
      hidden: true
    }
  ]
}
