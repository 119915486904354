export const convertToObject = <T>(
  data: T[],
  key = 'id'
): { [key: string]: T } =>
  data.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: item
    }),
    {}
  )

export const convertToArray = <T>(data: { [key: string]: T }): T[] =>
  Object.keys(data).map(key => data[key])
