import uuid from 'uuid/v4'
import { ISite } from '..'
import { IContentComponent, IContentComponentSchema } from './content'
import { IDataComponent, IDataComponentSchema } from './data'
import { IUtilityComponent, IUtilityComponentSchema } from './utility'
import { IGlobalComponent, IGlobalComponentSchema } from './global'

export type IComponent =
  | IContentComponent
  | IDataComponent
  | IUtilityComponent
  | IGlobalComponent

export type IComponentSchema =
  | IContentComponentSchema
  | IDataComponentSchema
  | IUtilityComponentSchema
  | IGlobalComponentSchema

export enum EFieldType {
  alignment = 'alignment',
  array = 'array',
  badgeValue = 'badgeValue',
  color = 'color',
  components = 'components',
  datetime = 'datetime',
  design = 'design',
  direction = 'direction',
  embed = 'embed',
  image = 'image',
  label = 'label',
  link = 'link',
  media = 'media',
  note = 'note',
  number = 'number',
  range = 'range',
  richtext = 'richtext',
  select = 'select',
  switch = 'switch',
  text = 'text',
  textarea = 'textarea',
  toggle = 'toggle',
  question = 'question'
}

export enum ELinkType {
  default = 'default',
  donate = 'donate',
  file = 'file',
  forgotPassword = 'forgotPassword',
  login = 'login',
  page = 'page',
  signup = 'signup'
}

export enum ESocialLinkType {
  facebook = 'facebook',
  twitter = 'twitter',
  instagram = 'instagram',
  linkedin = 'linkedin',
  youtube = 'youtube'
}

export interface IComponentSchemaBase<IComponent> {
  key: string
  description: string
  icon?: string
  name: string
  group?: string
  disabled?: boolean
  single?: boolean
  getDefaultValues: (
    data?: DeepPartial<IComponent>,
    site?: Partial<ISite>
  ) => IComponent
  fields: IComponentSchemaField[]
}

export interface IComponentSchemaField {
  config?: { [key: string]: any }
  fieldGroup?: string
  hidden?: boolean
  inline?: boolean
  label?: string
  key: string
  options?: Array<{ value: string; label: string }>
  placeholder?: string
  repeatableSchema?: IComponentSchema
  repeatableLabel?: any
  required?: boolean
  type: EFieldType
  visibleFields?: string[]
  toggletipTranslationKey?: string
}

export const deserializeComponent = <
  IComp extends IContentComponent | IGlobalComponent
>(
  component: IComp
) => {
  const addItemKeys = (component: IComp, itemsKeys = ['items']) => {
    return itemsKeys.reduce(
      (result, key) => ({
        ...result,
        [key]: component[key].map((item: any) => ({
          ...item,
          key: item.key || uuid()
        }))
      }),
      component
    )
  }

  switch (component.__component) {
    case 'content.gallery':
    case 'content.how-to-group':
    case 'content.resources':
    case 'content.sponsors':
      return addItemKeys(component)
    case 'content.buttons':
      return addItemKeys(component, ['buttons'])
    case 'content.metrics':
      return addItemKeys(component, ['metrics'])
    case 'content.faqs':
      return addItemKeys(component, ['questions'])
    case 'global.footer':
      return addItemKeys(component, ['buttons', 'links', 'socialLinks'])
    case 'global.header':
      return addItemKeys(component, ['buttons', 'links'])
    default:
      return component
  }
}
