import last from 'lodash/last'
import mapKeys from 'lodash/mapKeys'
import snakeCase from 'lodash/snakeCase'

// https://strapi.io/documentation/developer-docs/latest/developer-resources/content-api/content-api.html#filters
const filters = [
  'ne',
  'lt',
  'lte',
  'gt',
  'gte',
  'in',
  'nin',
  'contains',
  'ncontains',
  'containss',
  'ncontainss',
  'null'
]

export const snakeCaseParams = (params: any) =>
  mapKeys(params, (_val, original) => {
    const value = snakeCase(original)
    const suffix = last(value.split('_'))

    return filters.indexOf(suffix) > -1 ? value : original
  })
