import { Reducer } from 'redux'
import { useSelector } from 'react-redux'
import { IState, StoreDispatch } from '..'
import { IOrganisation } from '@sitebuilder/api'
import {
  fetchOrganisations as fetchOrganisationsData,
  IOrganisationsParams
} from '@sitebuilder/api/organisations'
import { EStatus } from '@sitebuilder/api/status'
import { formatError } from '@sitebuilder/api/errors'
import { convertToArray, convertToObject } from '../../lib/utils'
import { setFlash, ETheme } from '../flash'

// Types
export interface IOrganisationsState {
  data: {
    [key: string]: IOrganisation
  }
  status: EStatus
}

enum EActionTypes {
  FETCH = 'admin/organisations/FETCH',
  STORE = 'admin/organisations/STORE'
}

// Actions
interface IFetchOrganisationsAction {
  type: EActionTypes.FETCH
}

interface IStoreOrganisationsAction {
  type: EActionTypes.STORE
  payload: IOrganisation[]
}

type IOrganisationsAction =
  | IFetchOrganisationsAction
  | IStoreOrganisationsAction

export const fetchOrganisations = (params?: IOrganisationsParams) => async (
  dispatch: StoreDispatch
) => {
  return Promise.resolve()
    .then(() => dispatch({ type: EActionTypes.FETCH }))
    .then(() => fetchOrganisationsData(params))
    .then(data => dispatch({ type: EActionTypes.STORE, payload: data }))
    .catch(error => dispatch(setFlash(formatError(error), ETheme.FAILURE)))
}

// Selector
export const useOrganisations = () =>
  useSelector((state: IState) =>
    convertToArray<IOrganisation>(state.organisations.data)
  )

export const useOrganisationsStatus = () =>
  useSelector((state: IState) => state.organisations.status)

// Reducer
const initialState = {
  data: {},
  status: EStatus.PENDING
}

const reducer: Reducer<IOrganisationsState> = (
  state = initialState,
  action: IOrganisationsAction
) => {
  switch (action.type) {
    case EActionTypes.FETCH:
      return {
        data: {},
        status: EStatus.FETCHING
      }

    case EActionTypes.STORE:
      return {
        data: {
          ...state.data,
          ...convertToObject(action.payload, 'slug')
        },
        status: EStatus.FETCHED
      }

    default:
      return state
  }
}

export default reducer
