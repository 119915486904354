import get from 'lodash/get'
import last from 'lodash/last'
import { isStaging } from '../client'
import { ECountryCode } from '..'

export const baseUrl = (subdomain = 'www') =>
  `https://${subdomain}${isStaging ? '.staging' : ''}.justgiving.com`

const imageBaseUrl = () =>
  `https://images${isStaging ? '.staging' : ''}.justgiving.com`

export const getImageId = (url: string) =>
  typeof url === 'string' ? last(url.split('?')[0].split('/')) : null

export const imageUrl = (image: string) => {
  return image ? `${imageBaseUrl()}/image/${getImageId(image)}` : null
}

export const imageUrlWithTemplate = (url: string, template: string) =>
  [imageUrl(url), `template=${template}`].join('?')

export const oauthConnectUrl = [
  [
    `${baseUrl('identity')}/connect/authorize`,
    'scope=openid+profile+email+account+fundraise+offline_access'
  ].join('?'),
  `client_id=44f34c65`,
  'redirect_uri=https://oauth.blackbaud-sites.com/',
  'response_type=code'
].join('&')

export const countryFromCurrency = (currencyCode: string): ECountryCode => {
  switch (currencyCode.toUpperCase()) {
    case 'AUD':
      return ECountryCode.au
    case 'CAD':
      return ECountryCode.ca
    case 'NZD':
      return ECountryCode.nz
    case 'EUR':
      return ECountryCode.ie
    case 'GBP':
      return ECountryCode.uk
    case 'USD':
      return ECountryCode.us
  }
}

export const isValidJSON = (json: string) => {
  try {
    JSON.parse(json)
    return true
  } catch (error) {
    return false
  }
}

export const parseText = (text: string) => {
  const baseText = text || ''
  const content = baseText.replace(/\n/g, ' ')

  if (isValidJSON(content)) {
    return JSON.parse(content)
      .map(parseTextSection)
      .join(' ')
  }

  return baseText
}

const parseTextSection = (section: any) => {
  switch (section.type) {
    case 'paragraph':
      return get(section, 'nodes.0.ranges.0.text', '')
    case 'header':
      return get(section, 'text', '')
    default:
      return ''
  }
}

// Workaround for BUG ADO-2733365
export const nonSiteBuilderFRP = (legacyId: string) => {
  const guids = [
    'df8e82a2-aba5-425b-90a1-3c2258441b8e',
    'f8033069-b9fc-438b-b843-4f73d008fe96',
    '59db400c-7b7d-41a0-9532-3b4340424b83',
    '431f5e6e-890c-497b-a2fc-aabcc2789f49',
    // Staging guid to test
    '1685d90c-15e1-4f4d-98fd-011b70004835',
    '2b6ed695-4d47-4489-a50a-c2aeb92ae7de'
  ]
  const filteredGuid = guids.filter(guid => guid === legacyId).length > 0

  return filteredGuid
}
