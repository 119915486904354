/* istanbul ignore file */
import { InitOptions } from 'i18next'

const i18nConfig: InitOptions = {
  supportedLngs: ['en', 'en-GB', 'en-US'],
  fallbackLng: 'en',
  ns: ['admin'],
  defaultNS: 'admin',
  keySeparator: false,
  debug: false
}

export default i18nConfig
