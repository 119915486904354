import set from '../../lib/set'
import { Reducer } from 'redux'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { IState, StoreDispatch } from '..'
import { ISite } from '@sitebuilder/api'
import {
  fetchSites as fetchSitesData,
  fetchSite as fetchSiteData,
  ISitesParams
} from '@sitebuilder/api/sites'
import { EStatus } from '@sitebuilder/api/status'
import { formatError } from '@sitebuilder/api/errors'
import { convertToArray, convertToObject } from '../../lib/utils'
import { setFlash, ETheme } from '../flash'

// Types
export interface ISitesState {
  data: {
    [key: string]: ISite
  }
  status: EStatus
}

enum EActionTypes {
  FETCH = 'admin/sites/FETCH',
  STORE_SITES = 'admin/sites/STORE_SITES',
  STORE_SITE = 'admin/sites/STORE_SITE',
  UPDATE_HEADER = 'admin/sites/UPDATE_HEADER',
  UPDATE_FOOTER = 'admin/sites/UPDATE_FOOTER',
  UPDATE_META_TAGS = 'admin/sites/UPDATE_META_TAGS'
}

// Actions
interface IFetchSitesAction {
  type: EActionTypes.FETCH
}

interface ISitesSuccessAction {
  type: EActionTypes.STORE_SITES
  payload: ISite[]
}

interface IStoreSiteAction {
  type: EActionTypes.STORE_SITE
  payload: ISite
}

interface IUpdateHeaderAction {
  type: EActionTypes.UPDATE_HEADER
  payload: { slug: string; key: string; value: any }
}

interface IUpdateFooterAction {
  type: EActionTypes.UPDATE_FOOTER
  payload: { slug: string; key: string; value: any }
}

interface IUpdateMetaAction {
  type: EActionTypes.UPDATE_META_TAGS
  payload: { slug: string; key: string; value: any }
}

type ISitesAction =
  | IFetchSitesAction
  | ISitesSuccessAction
  | IStoreSiteAction
  | IUpdateHeaderAction
  | IUpdateFooterAction
  | IUpdateMetaAction

export const fetchSites = (params?: ISitesParams) => async (
  dispatch: StoreDispatch
) => {
  return Promise.resolve()
    .then(() => dispatch({ type: EActionTypes.FETCH }))
    .then(() => fetchSitesData(params))
    .then(data => dispatch({ type: EActionTypes.STORE_SITES, payload: data }))
    .catch(error => {
      dispatch(setFlash(formatError(error), ETheme.FAILURE))
      return Promise.reject(error)
    })
}

export const fetchSite = (slug: string) => async (dispatch: StoreDispatch) => {
  return Promise.resolve()
    .then(() => dispatch({ type: EActionTypes.FETCH }))
    .then(() =>
      fetchSiteData(slug, {
        mode: 'draft',
        includePlaceholderAttachments: true
      })
    )
    .then(data => dispatch({ type: EActionTypes.STORE_SITE, payload: data }))
    .catch(error => {
      dispatch(setFlash(formatError(error), ETheme.FAILURE))
      return Promise.reject(error)
    })
}

export const storeSite = (site: ISite): ISitesAction => ({
  type: EActionTypes.STORE_SITE,
  payload: site
})

export const updateHeader = (
  slug: string,
  key: string,
  value: any
): IUpdateHeaderAction => ({
  type: EActionTypes.UPDATE_HEADER,
  payload: { slug, key, value }
})

export const updateFooter = (
  slug: string,
  key: string,
  value: any
): IUpdateFooterAction => ({
  type: EActionTypes.UPDATE_FOOTER,
  payload: { slug, key, value }
})

export const updateMetaTags = (
  slug: string,
  key: string,
  value: any
): IUpdateMetaAction => ({
  type: EActionTypes.UPDATE_META_TAGS,
  payload: { slug, key, value }
})

// Selector
export const useSites = () =>
  useSelector((state: IState) => convertToArray<ISite>(state.sites.data))
export const useSite = (slug: string) =>
  useSelector((state: IState) => state.sites.data[slug])
export const useCurrentSite = () => useSite(useParams<{ site: string }>().site)
export const useCurrentSiteColors = () => useCurrentSite().colors
export const useCurrentSiteFonts = () => useCurrentSite().fonts
export const useCurrentSiteDefaults = () => useCurrentSite().defaults
export const useSitesStatus = () =>
  useSelector((state: IState) => state.sites.status)

// Reducer
const initialState = {
  data: {},
  status: EStatus.PENDING
}

const reducer: Reducer<ISitesState> = (
  state = initialState,
  action: ISitesAction
) => {
  switch (action.type) {
    case EActionTypes.FETCH:
      return {
        data: {},
        status: EStatus.FETCHING
      }

    case EActionTypes.STORE_SITES:
      return {
        data: {
          ...state.data,
          ...convertToObject(action.payload, 'slug')
        },
        status: EStatus.FETCHED
      }

    case EActionTypes.STORE_SITE:
      return {
        ...state,
        status: EStatus.FETCHED,
        data: {
          ...state.data,
          [action.payload.slug]: action.payload
        }
      }

    case EActionTypes.UPDATE_HEADER: {
      const slug = action.payload.slug
      const site = state.data[action.payload.slug]

      return {
        ...state,
        data: {
          ...state.data,
          [slug]: {
            ...site,
            header: set(site.header, action.payload.key, action.payload.value)
          }
        }
      }
    }

    case EActionTypes.UPDATE_FOOTER: {
      const slug = action.payload.slug
      const site = state.data[action.payload.slug]

      return {
        ...state,
        data: {
          ...state.data,
          [slug]: {
            ...site,
            footer: set(site.footer, action.payload.key, action.payload.value)
          }
        }
      }
    }

    case EActionTypes.UPDATE_META_TAGS: {
      const slug = action.payload.slug
      const site = state.data[action.payload.slug]
      return {
        ...state,
        data: {
          ...state.data,
          [slug]: {
            ...site,
            meta: {
              ...site.meta,
              ...action.payload.value
            }
          }
        }
      }
    }

    default:
      return state
  }
}

export default reducer
