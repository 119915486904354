import createStyles, { typography, colors } from '../../../lib/traits';

export default createStyles({
  ':root': {
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    background: colors.offWhite,
    color: colors.darkGray,
    ...typography.body,
  },
  a: {
    textDecoration: 'none',
    color: 'inherit',
  },
});
