export enum EStatus {
  FETCHING = 'fetching',
  FETCHED = 'fetched',
  FAILURE = 'failure',
  SAVING = 'saving',
  PUBLISHING = 'publishing',
  PENDING = 'pending'
}

export const shouldFetch = (status: EStatus): boolean =>
  status !== EStatus.FETCHED && status !== EStatus.FETCHING

export const combineStatuses = (...statuses: EStatus[]): EStatus => {
  if (statuses.length < 1) return null
  if (statuses.indexOf(EStatus.FAILURE) > -1) return EStatus.FAILURE
  if (statuses.indexOf(EStatus.FETCHING) > -1) return EStatus.FETCHING
  if (statuses.indexOf(EStatus.SAVING) > -1) return EStatus.SAVING
  if (statuses.indexOf(EStatus.PUBLISHING) > -1) return EStatus.PUBLISHING
  if (statuses.indexOf(EStatus.PENDING) > -1) return EStatus.PENDING

  return EStatus.FETCHED
}
