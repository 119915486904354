import client from '../client'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { colorsSchema } from '../components/design/colors'
import { deserializeComponent } from '../components'
import { EStatus } from '../status'
import { fontsSchema } from '../components/design/fonts'
import { formatDateISO } from '@sitebuilder/shared/lib/date'
import { imageUrl } from '@sitebuilder/justgiving/utils'
import { IAttachmentResponse, deserializeAttachment } from '../attachments'
import { parseCountryCode, restrictPersonalDataOptions } from '../utils'
import { snakeCaseParams } from '../params'
import { IGlobalCustomDomainComponent } from '../components/global/customDomain'
import {
  IGlobalBadgesComponent,
  badgesSchema
} from '../components/global/badges'
import {
  IGlobalFitnessComponent,
  fitnessSchema
} from '../components/global/fitness'
import {
  IGlobalFooterComponent,
  footerSchema
} from '../components/global/footer'
import {
  IGlobalHeaderComponent,
  headerSchema
} from '../components/global/header'
import {
  IGlobalRegistrationComponent,
  registrationSchema
} from '../components/global/registration'
import {
  IGlobalThankYouComponent,
  thankYouSchema
} from '../components/global/thankYou'
import {
  IGlobalTrackingComponent,
  trackingSchema
} from '../components/global/tracking'
import { IGlobalMetaComponent, metaTagsSchema } from '../components/global/meta'
import {
  ECountryCode,
  IFile,
  IOrganisation,
  ISite,
  ISiteCampaign,
  ISiteColors,
  ISiteDefaults,
  ISiteFonts,
  IUser
} from '..'
import { IUtilityBadgeComponent } from '../components/utility/badge'
import {
  emailCoverSchema,
  IGlobalEmailCoverComponent
} from '../components/global/emailCover'
import {
  emailCampaignLogoSchema,
  IGlobalEmailCampaignLogoComponent
} from '../components/global/emailCampaignLogo'

export interface ISiteResponse {
  attachments: IAttachmentResponse[]
  author: IUser
  badges: IGlobalBadgesComponent
  created_at: string
  campaignGuid: string
  campaign: ISiteCampaign
  colors: ISiteColors
  countryCode: ECountryCode
  customDomain: IGlobalCustomDomainComponent
  defaults: ISiteDefaults
  eventId: string
  fitness: IGlobalFitnessComponent
  fonts: ISiteFonts
  footer: IGlobalFooterComponent
  header: IGlobalHeaderComponent
  id: string
  isLive: boolean
  meta: IGlobalMetaComponent
  name: string
  organisation: IOrganisation
  owner: IUser
  production: boolean
  published_at: string
  siteStatus: 'archived' | 'draft' | 'published'
  registrationConfig: IGlobalRegistrationComponent
  emailCover: IGlobalEmailCoverComponent
  emailCampaignLogo: IGlobalEmailCampaignLogoComponent
  slug: string
  state: string
  teamBadges: IGlobalBadgesComponent
  thankYou: IGlobalThankYouComponent
  tracking: IGlobalTrackingComponent
  type: string
  updated_at: string
  siteScreenshot: string
  gqlClientKey: string
}

export interface ISiteDto extends Omit<ISite, 'organisation'> {
  organisation: string
}

export const defaultSiteValues: Partial<ISite> = {
  badges: badgesSchema.getDefaultValues(),
  colors: colorsSchema.getDefaultValues(),
  fitness: fitnessSchema.getDefaultValues(),
  fonts: fontsSchema.getDefaultValues(),
  footer: footerSchema.getDefaultValues(),
  header: headerSchema.getDefaultValues(),
  meta: metaTagsSchema.getDefaultValues(),
  registrationConfig: registrationSchema.getDefaultValues(),
  emailCover: emailCoverSchema.getDefaultValues(),
  emailCampaignLogo: emailCampaignLogoSchema.getDefaultValues(),
  teamBadges: badgesSchema.getDefaultValues(),
  thankYou: thankYouSchema.getDefaultValues(),
  tracking: trackingSchema.getDefaultValues(),
  defaults: {
    button: {
      borderRadius: 0.25,
      spacing: [0.75, 1.25, 0.75, 1.25]
    },
    input: {
      borderRadius: 0.25,
      spacing: [0.75, 1]
    }
  }
}

const deserializeBadges = (badges: any) => {
  const data = get(badges, 'data', []) as IUtilityBadgeComponent[]

  return {
    ...badges,
    data: data.map(badge => ({
      ...badge,
      image: isEmpty(badge.image) ? null : badge.image
    }))
  }
}

const constructIFile = (imageGuid: string): IFile => {
  const url = imageUrl(imageGuid)
  const extension = imageGuid.split('.')[1]
  return {
    name: 'headerImage',
    alternativeText: null,
    caption: null,
    width: 1080,
    height: 720,
    formats: {
      thumbnail: {
        ext: `.${extension}`,
        url: url,
        hash: 'header_image',
        mime: `image/${extension}`,
        path: null,
        size: 8.25,
        width: 234,
        height: 156
      },
      large: null,
      medium: null,
      small: null
    },
    ext: `.${extension}`,
    url: url
  }
}
export const deserializeSite = (data: ISiteResponse): ISite => {
  const rootDomain =
    process.env.SITE_DOMAIN || process.env.REACT_APP_SITE_DOMAIN
  const hasCustomDomain = get(data, 'customDomain.status') === 'complete'
  const domain = hasCustomDomain
    ? data.customDomain.domain
    : [data.slug, rootDomain].join('.')

  return {
    attachments: data.attachments.map(attachment =>
      deserializeAttachment(attachment)
    ),
    author: data.author,
    badges: deserializeBadges(data.badges),
    campaignGuid: data.campaignGuid,
    campaign: data.campaign,
    colors: data.colors,
    countryCode: data.countryCode,
    customDomain: data.customDomain,
    defaults: data.defaults,
    domain,
    eventId: data.eventId,
    fitness: data.fitness || fitnessSchema.getDefaultValues(),
    fonts: data.fonts,
    footer: deserializeComponent<IGlobalFooterComponent>(data.footer),
    header: deserializeComponent<IGlobalHeaderComponent>(data.header),
    id: data.id,
    isLive: data.isLive,
    meta: data.meta || metaTagsSchema.getDefaultValues(),
    name: data.name,
    organisation: data.organisation,
    owner: data.owner,
    production: data.production,
    siteStatus: data.siteStatus,
    registrationConfig: data.registrationConfig,
    emailCover: data.emailCover,
    emailCampaignLogo: data.emailCampaignLogo,
    slug: data.slug,
    status: EStatus.FETCHED,
    teamBadges: deserializeBadges(data.teamBadges),
    thankYou: data.thankYou || thankYouSchema.getDefaultValues(),
    tracking: data.tracking || trackingSchema.getDefaultValues(),
    type: data.type || 'published',
    url: `${
      process.env.NODE_ENV === 'production' ? 'https' : 'http'
    }://${domain}`,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
    siteScreenshot: data.siteScreenshot,
    gqlClientKey: data.gqlClientKey
  }
}

export interface ISitesParams {
  mode?: 'draft' | 'published'
  includePlaceholderAttachments?: boolean
  isLive?: string
  nameContains?: string
  organisation?: string
  owner?: string
  createdAtGte?: string
  createdAtLte?: string
  updatedAtGte?: string
  updatedAtLte?: string
  _sort?: string
}

export interface IUpdateSiteParams {
  mode?: 'draft' | 'published'
}

export const fetchSites = async (
  params: ISitesParams = {}
): Promise<ISite[]> => {
  const res = await client.get('/sites', { params: snakeCaseParams(params) })
  const activeSites = res.data.filter(
    (site: { siteStatus: string }) => site.siteStatus !== 'archived'
  )
  return activeSites.map(deserializeSite)
}

export const fetchSite = async (
  slug: string,
  params: ISitesParams = {}
): Promise<ISite> => {
  const res = await client.get(`/sites/${slug}`, { params })

  return deserializeSite(res.data)
}

export const createSite = async (
  organisation: IOrganisation,
  site: Partial<ISiteDto>
): Promise<ISite> => {
  const payload = {
    ...defaultSiteValues,
    colors: get(organisation, 'colors', defaultSiteValues.colors),
    fonts: get(organisation, 'fonts', defaultSiteValues.fonts),
    name: site.name,
    slug: site.slug,
    organisation: site.organisation,
    campaignGuid: site.campaign.campaignGuid,
    isLive: false,
    countryCode: parseCountryCode(organisation.charity.countryCode),
    campaign: {
      ...site.campaign,
      countryCode: parseCountryCode(organisation.charity.countryCode),
      startDate: site.campaign.startDate
        ? formatDateISO(site.campaign.startDate)
        : null,
      endDate: site.campaign.endDate
        ? formatDateISO(site.campaign.endDate)
        : null,
      isSitebuilder: true
    },
    siteStatus: 'draft',
    registrationConfig: {
      privacyUrl: organisation.privacyUrl,
      termsUrl: organisation.termsUrl,
      personalData: restrictPersonalDataOptions(
        defaultSiteValues.registrationConfig.personalData,
        organisation.charity.countryCode
      )
    },
    meta: {
      title: site.name,
      description: organisation.charity.description,
      image: null
    }
  }

  const response = await client.post('/sites', payload)

  return deserializeSite(response.data)
}

export const updateSite = async (
  id: string,
  data: Partial<ISite>,
  params: IUpdateSiteParams = {}
) => {
  const res = await client.put(`/sites/${id}`, data, { params })

  return deserializeSite(res.data)
}

export const updateAnalytics = async (id: string, data: any) => {
  return await client.put(`/sites/${id}/update-tracking`, data)
}

export const updateEmailBranding = async (id: string, data: any) => {
  const res = await client.put(`/sites/${id}/update-email-branding`, data)
  return res
}

export const getBrandingLogo = async (id: string) =>
  client
    .get(`/sites/${id}/brandingLogo`)
    .then(res => (res.data ? constructIFile(res.data) : null))

export const archiveSite = async (id: string) => {
  const response = await client.put(`/sites/${id}/archive`)
  return deserializeSite(response.data)
}

export const setSiteStatus = (site: ISite, isLive: boolean) =>
  updateSite(site.id, { isLive, siteStatus: isLive ? 'published' : 'draft' })

export const setArchiveStatus = (site: ISite) => archiveSite(site.id)

export const deleteSite = (site: ISite) => client.delete(`/sites/${site.id}`)

export const isSlugAvailable = (slug: string) =>
  client
    .get(`/sites/${slug}/availability`)
    .then(response => response.data.available)

export const uploadSitePreview = (id: string) =>
  client
    .post(`sites/${id}/image`, {})
    .then(response => deserializeSite(response.data))

export const addCustomDomain = (
  id: string,
  data: Partial<IGlobalCustomDomainComponent>
) =>
  client
    .post(`/sites/${id}/custom-domain`, data)
    .then(response => deserializeSite(response.data))

export const disableCustomDomain = (id: string) =>
  client
    .delete(`/sites/${id}/custom-domain/disable`)
    .then(response => deserializeSite(response.data))

export const deleteCustomDomain = (id: string) =>
  client
    .delete(`/sites/${id}/custom-domain`)
    .then(response => deserializeSite(response.data))

export const checkDomainStatus = (id: string) =>
  client
    .get(`/sites/${id}/custom-domain/status`)
    .then(response => deserializeSite(response.data))

export const addFitnessTagsToCampaign = (guid: string) =>
  client.put(`/sites/${guid}/fitness-tags`)
