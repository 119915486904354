import merge from 'lodash/merge'
import { EFieldType, IComponentSchemaBase } from '../..'
import { IFile } from '../../..'

export interface IGlobalMetaComponent {
  __component: 'meta.tags'
  id?: string
  title: string
  description: string
  image: IFile
}

export type IGlobalMetaComponentSchema = IComponentSchemaBase<
  IGlobalMetaComponent
>

export const metaTagsSchema: IGlobalMetaComponentSchema = {
  key: 'meta.tags',
  description: 'Set the meta tags for your site',
  name: 'Meta Tags',
  getDefaultValues: values =>
    merge(
      {
        __component: 'meta.tags',
        title: 'Campaign Title',
        description: 'A brief summary of your campaign',
        image: null
      },
      values
    ),
  fields: [
    {
      key: 'title',
      type: EFieldType.text,
      label: 'Title'
    },
    {
      key: 'description',
      type: EFieldType.text,
      label: 'Description'
    },
    {
      key: 'image',
      type: EFieldType.image,
      label: 'Favicon'
    }
  ]
}
