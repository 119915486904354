import React from 'react'
import { Switch, Route as ReactRoute } from 'react-router-dom'

interface IProps {
  exact?: boolean
  path: string
  component: any
  children?: any
}

const Route: React.FC<IProps> = ({
  component: RouteComponent,
  exact,
  path,
  children
}) => (
  <ReactRoute exact={exact} path={path}>
    <RouteComponent>{children && <Switch>{children}</Switch>}</RouteComponent>
  </ReactRoute>
)

export default Route
