import React, { useEffect } from 'react'
import { Global } from '@emotion/core'
import Modal from 'react-modal'
import 'normalize.css'
import styles from './styles'

const BaseStyles = ({ children }) => {
  useEffect(() => {
    Modal.setAppElement('#root')
  }, [])

  return (
    <>
      <Global styles={styles} />
      {children}
    </>
  )
}

export default BaseStyles
