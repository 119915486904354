import { keyframes } from '@emotion/core'
import createStyles from '../../../lib/traits'

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export default createStyles({
  root: {
    position: 'relative',
    display: 'block',
    margin: '4rem auto',
    height: '2rem',
    width: '2rem',
    overflow: 'hidden',
    textAlign: 'left',
    textIndent: '-9999px',
    border: 'thin solid transparent',
    borderRightColor: 'inherit',
    borderRadius: '50%',
    animation: `${spin} 1s linear infinite`
  }
})
